import { Component, Inject, OnInit, isDevMode } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationService } from './shared/services/notification.service';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'dtse-translation';
  isIframe = false;
  loginDisplay = false;
  errorMessage: string = '';
  successMessage: string = '';
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private notificationService: NotificationService,
    private cookieService: CookieService
  ) {}

  async ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS ||
            msg.eventType === EventType.LOGIN_SUCCESS
        )
      )
      .subscribe((result: EventMessage) => {
        /* if (result?.eventType == 'msal:acquireTokenSuccess') {
          const payload: any = result.payload;
          const token = payload.accessToken;
          console.log('token>>', token);
        } */
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    // Retrieve and handle local storage items containing "accessToken"
    const accessTokenKeyValues = this.getKeyWithValueContainingAccessToken();
    const authToken = JSON.parse(accessTokenKeyValues[0].value).secret;
    if (authToken) {
      // Delete the existing cookie before setting a new one
      this.cookieService.delete('authToken');
      // Set the new auth token cookie
      this.cookieService.set('authToken', authToken);
    }
    if (isDevMode()) {
      console.log('Project running in Non Production mode!');
    } else {
      console.log('Project running in Production mode!');
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  getKeyWithValueContainingAccessToken() {
    // Array to store key-value pairs
    const keyValuePairs: { key: string; value: string }[] = [];

    // Iterate over all keys in local storage
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key !== null) {
        const value = localStorage.getItem(key);
        // Check if the key contains "accessToken"
        if (key.includes('accesstoken') && value !== null) {
          keyValuePairs.push({ key, value });
        }
      }
    }

    return keyValuePairs;
  }

  ngOnDestroy(): void {
    this.notificationService.errorMessage$.subscribe(message => {
      this.errorMessage = message;
    });

    this.notificationService.successMessage$.subscribe(message => {
      this.successMessage = message;
    });

    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
