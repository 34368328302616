<div class="grid-container">
  <div class="header">
    <app-header></app-header>
  </div>
  <div class="back-link">
    <!-- <a routerLink="/translate">Back to Translate</a> -->
    <scale-icon-home-home
      accessibility-title="home"
      routerLink="/translate"
      fill="#e20074"
      style="cursor: pointer" />
  </div>
  <div class="content setting-content">
    <scale-sidebar-nav>
      <scale-sidebar-nav-item
        *ngIf="showEnableLanguageSetting"
        [current]="activeComponent === 'languages'"
        [active]="activeComponent === 'languages'">
        <a href="javascript:void(0)" (click)="setActiveComponent('languages')"
          >Languages</a
        >
      </scale-sidebar-nav-item>

      <scale-sidebar-nav-item
        *ngIf="showGlossaryMenu"
        [current]="activeComponent === 'glossary'"
        [active]="activeComponent === 'glossary'">
        <a href="javascript:void(0)" (click)="setActiveComponent('glossary')"
          >Glossary</a
        >
      </scale-sidebar-nav-item>
      <scale-sidebar-nav-item
        *ngIf="showDashboard"
        [current]="activeComponent === 'dashboard'"
        [active]="activeComponent === 'dashboard'">
        <a href="javascript:void(0)" (click)="setActiveComponent('dashboard')"
          >Dashboard</a
        >
      </scale-sidebar-nav-item>
      <scale-sidebar-nav-item
        *ngIf="showAppConfiguration"
        [current]="activeComponent === 'configuration'"
        [active]="activeComponent === 'configuration'">
        <a
          href="javascript:void(0)"
          (click)="setActiveComponent('configuration')"
          >Configuration</a
        >
      </scale-sidebar-nav-item>
    </scale-sidebar-nav>
    <div class="setting-component">
      <div *ngIf="activeComponent === 'languages'">
        <app-languages></app-languages>
      </div>

      <div *ngIf="activeComponent === 'glossary'">
        <app-glossary></app-glossary>
      </div>

      <div *ngIf="activeComponent === 'dashboard'">
        <app-dashboard></app-dashboard>
      </div>
      <div *ngIf="activeComponent === 'configuration'">
        <app-configuration></app-configuration>
      </div>
    </div>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</div>
