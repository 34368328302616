<scale-telekom-header>
  <scale-telekom-nav-list slot="main-nav" aria-label="Translation Suite Links">
    <scale-telekom-nav-item aria-label="Translation Suite">
      <button
        type="button"
        aria-label="Translation Suite page"
        class="translation-suite-btn">
        Translation Suite
      </button>
    </scale-telekom-nav-item>
  </scale-telekom-nav-list>

  <scale-telekom-nav-list variant="functions" slot="functions" alignment="right" aria-label="Functions Menu" role="none">
    <scale-telekom-nav-item class="user-actions" accessibility-title="Setting"  aria-label="Setting"
    tabindex="0" *ngIf="showSettings"
    role="button" (keydown.enter)="navigateToSettings()" 
    (keydown.space)="navigateToSettings()">
      <div class="icon-container">
        <scale-icon-service-settings
          size="33"
          (click)="navigateToSettings()"
          (keydown.enter)="navigateToSettings()" 
          (keydown.space)="navigateToSettings()"
        ></scale-icon-service-settings>
      </div>
      <div class="label-container">
        <label for="Setting" (click)="navigateToSettings()"  aria-hidden="true">Setting</label>
      </div>
    </scale-telekom-nav-item>
    <scale-telekom-nav-item class="user-actions" tabindex="0"  
      role="button"  accessibility-title="Logout"  aria-label="Logout" (keydown.enter)="logout()" 
      (keydown.space)="logout()">
      <div class="icon-container">
        <scale-icon-action-logout
          size="33"
          (click)="logout()"
          aria-label="Logout"></scale-icon-action-logout>
      </div>
      <div class="label-container">
        <label for="logout" (click)="logout()" aria-hidden="true">Logout</label>
      </div>
    </scale-telekom-nav-item>    
  </scale-telekom-nav-list>
</scale-telekom-header>
