<div class="grid-container">
  <div class="header">
   
  </div>
  <div class="content">
    <div class="col-s-12 offset-s-0 col-m-12 col-l-6">
      <div class="cu-login__layer">
        <p class="justify-content"><b>Data Privacy</b> 
            <br><br>HANDLING DATA IN TRANSLATION SUITE & INFORMATION PROTECTION CLASSES
            <br><br>In the Translation Suite you are allowed to translate data and information of the protection classes “OPEN” and “INTERNAL” without restrictions. 
            <br><br>Example for data protection class OPEN (DPC0): anonymized information press publications. 
            <br><br>Example for data protection class INTERNAL (DPC1): Professional telephone number; employee e-mail Organizational unit (OrGE) of employees.
            <br><br>Regarding information of the data protection class “CONFIDENTIAL” you must differentiate: You are allowed to use the Translation Suite up to the data protection class 2 (DPC2) and only for tasks that have been processed according to the DTSE Magenta Services Offshore Project. Example for data protection class Confidential DPC2: Address/contact data contractual partner private contact details of employees date of birth customer account number.
            <br><br>Information texts documents images that are classified as "CONFIDENTIAL" of the DPC3 must NOT be inserted in any case. For example: sensitive employee data (such as health information severe disability appraisals sanctions during employment relationship) traffic data or customer password.
            <br><br><b>It is strictly prohibited to use the Translation Suite for the translation of VS-NfD Data (Verschlusssache – Nur für den Dienstgebrauch) civil servant data telecommunication secrecy or lawful interception data.</b>
            <br><br>You can find further information on the data protection classes in YAM under <a target="_blank" href="https://yam-united.telekom.com/pages/group-privacy/apps/wiki/wiki/list/view/3f68fe8d-6303-44c2-aa75-634805cf67ff?currentLanguage=EN">data protection class.</a> 
            <br><br>The TRANSLATION SUITE may only be used for professional or business purposes. 
            <br>TRANSLATION SUITE runs on Microsoft Azure Cognitive Services Translator that is a cloud-based machine translation service and is part of the Azure Cognitive Services family of cognitive APIs for building intelligent apps. Data submitted for translation to Azure Cognitive Services Translator are not written to persistent storage. There will be no record of the submitted text or any portion thereof in any Microsoft data center. The text will not be used for training purposes either. No cookies are stored in this service.
            <br><br><b>Terms and conditions</b>
            <br><br>1. Subject of Terms of Use - The following provisions define the terms for using the TRANSLATION SUITE platform. The platform includes translation service to a variety of DT-Group-relevant languages available for users to translate texts documents (ppt doc pdf txt) images (jpegjpgbmp) website and live screen (anything on the screen) of OPENINTERNAL and CONFIDENTIAL (up to DPC2) information protection classes. Users are employees of the Deutsche Telekom Group.
            <br><br>2. Purpose - The TRANSLATION SUITE may only be used for professional or business purposes. The Content made available by Deutsche Telekom shall be solely used for exercising/supporting an activity for the Deutsche Telekom Group.
            <br><br>3. Liability - Deutsche Telekom does not guarantee that the translation/translated information provided on this website is complete correct and up to date in all cases. Deutsche Telekom reserves the right to make changes or additions to the information provided or to remove it without prior notice. In no event shall Deutsche Telekom be liable.
            <br>Civil Servant Data: only business contact data of Civil Servants can be translated in the Translation Suite. for any damages whatsoever resulting from loss of use or data in connection with the use of information or the performance of services available from this website.
            <br><br>4. Confidentiality - The platform includes translation service to a variety of DT-Group-relevant languages available for users to translate texts documents (ppt doc pdf txt) images (jpegjpgbmp) website and live screen (anything on the screen) of OPEN INTERNAL and CONFIDENTAL (DPC2) information protection classes. Users are employees of the Deutsche Telekom Group. Users shall take all necessary measures to prevent third parties from obtaining Internal Information.
            <br><br>5. Modifications to Terms of Use - Deutsche Telekom shall be free to modify the terms of use at any time and to any extent whatsoever. Users must be notified of all modifications to the terms of use in writing or in text form including fax or e-mail. Modifications shall be binding for users from the time they receive such notification.
          </p>
        
      </div>
    </div>
  </div>
  <div class="footer">
    
  </div>
</div>

