import { Component } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import {
  ALL_ACCESS_ROLE,
  LIVESCREEN_TRANSLATION_ROLE,
  WEBAPP_IMAGEDOC_TRANSLATION_ROLE,
  WEBAPP_TEXT_TRANSLATION_ROLE,
  WEBSITE_TRANSLATION_ROLE,
} from '../shared/data/common-data';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  loginDisplay = false;

  isTextTranslationVisible: Boolean = false;
  isImageDocTranslationVisible: Boolean = false;
  isWebsiteTranslationVisible: Boolean = false;
  isLiveScreenVisible: Boolean = false;

  userRolesRaw: string[] = [];
  userRolesProcessed: string[] = [];

  imageDocTranslationRole = WEBAPP_IMAGEDOC_TRANSLATION_ROLE;
  textTranslationRole = WEBAPP_TEXT_TRANSLATION_ROLE;
  allAccessRole = ALL_ACCESS_ROLE;
  websiteTranslationRole = WEBSITE_TRANSLATION_ROLE;
  liveScreenTranslationRole = LIVESCREEN_TRANSLATION_ROLE;

  constructor(
    private msalAuthService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnInit() {
    // Listen for login success event
    this.msalBroadcastService.msalSubject$.subscribe((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        this.getUserRoles();
      }
    });

    this.getUserRoles(); // Initial roles fetch
  }

  // Retrieve roles from the token
  getUserRoles() {
    const account = this.msalAuthService.instance.getAllAccounts()[0]; // Assumes single account is logged in
    const idToken = account.idTokenClaims; // Access the ID token

    // Extract roles from the token (adjust depending on how roles are included in the token)
    if (idToken) {
      this.userRolesRaw = idToken.roles || []; // Roles claim is usually an array
      this.userRolesRaw.forEach(eachRole => {
        this.userRolesProcessed.push(eachRole.split('_')[1]);
      });
      this.isImageDocTranslationVisible = this.hasRole(
        this.imageDocTranslationRole
      );
      this.isTextTranslationVisible = this.hasRole(this.textTranslationRole);
      this.isLiveScreenVisible = this.hasRole(this.liveScreenTranslationRole);
      this.isWebsiteTranslationVisible = this.hasRole(
        this.websiteTranslationRole
      );
    }
  }

  hasRole(role: string): boolean {
    return (
      this.userRolesProcessed.includes(this.allAccessRole) ||
      this.userRolesProcessed.includes(role)
    );
  }
  /*  this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalAuthService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    this.isTextVisible = this.authenticationService.isTextTranslationVisible();
    this.isImageDocVisible =
      this.authenticationService.isDocImageTranslationVisible();
  }

  setLoginDisplay() {
    this.loginDisplay =
      this.msalAuthService.instance.getAllAccounts().length > 0;
  } */
}
