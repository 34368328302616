<div class="grid-container">
  <div class="header">
    <scale-telekom-header
      app-name=""
      app-name-link=""
      meta-nav-aria-label="Meta navigation section"
      meta-nav-external-aria-label="External navigation section"
      lang-switcher-aria-label="Language switcher section"
      main-nav-aria-label="Main navigation section">
    </scale-telekom-header>
  </div>
  <div class="content">
    <div class="col-s-12 offset-s-0 col-m-12 col-l-6">
      <div class="cu-login__layer">
        <h1 class="cu-login__headline">
          Welcome to<br />
          <span class="cu-login__headline-ultra">Translation Suite</span>
        </h1>
        <fieldset class="cu-login-form__fieldset">
          <form
            #loginForm="ngForm"
            (ngSubmit)="loginForm.valid && loginRedirect()"
            class="cu-login-form">
            <div>
              <div class="tacbox">
                <input id="checkbox" type="checkbox" [(ngModel)]="isAgreed" name="term"/>
                <label for="checkbox"> I agree to these <span
                  (click)="loadTermsAndConditions()"
                  class="terms-link"
                  tabindex="0"
                  role="link"
                >
                  Terms and Conditions
                </span>.</label>
              </div>
            </div>
            <div>
              <scale-button [disabled]="!isAgreed" type="submit" 
                ><app-loading-spinner></app-loading-spinner>Login</scale-button
              >
            </div>
          </form>
        </fieldset>
        
      </div>
    </div>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</div>


<div *ngIf="showTermsAndConditions">
  <scale-modal
    heading="Disclaimer"
    size="large"
    opened="showTermsAndConditions" omit-close-button="true">
    <ng-select 
      id="sourceLanguageSelect"
      class="grid-item-language" 
      [items]="languages"
      [ngModel]="selectedLanguage"
      bindLabel="name"
      (ngModelChange)="onLanguageChange($event)"
      [clearable]="false"
      [searchable]="false"
      aria-label="Select Language"
      aria-required="true">
    </ng-select>
    <p *ngIf="selectedLanguage == 'English'" class="justify-content"><b>Data Privacy</b> 
      <br><br>HANDLING DATA IN TRANSLATION SUITE & INFORMATION PROTECTION CLASSES
      <br><br>In the Translation Suite you are allowed to translate data and information of the protection classes “OPEN” and “INTERNAL” without restrictions. 
      <br><br>Example for data protection class OPEN (DPC0): anonymized information press publications. 
      <br><br>Example for data protection class INTERNAL (DPC1): Professional telephone number; employee e-mail Organizational unit (OrGE) of employees.
      <br><br>Regarding information of the data protection class “CONFIDENTIAL” you must differentiate: You are allowed to use the Translation Suite up to the data protection class 2 (DPC2) and only for tasks that have been processed according to the DTSE Magenta Services Offshore Project. Example for data protection class Confidential DPC2: Address/contact data contractual partner private contact details of employees date of birth customer account number.
      <br><br>Information texts documents images that are classified as "CONFIDENTIAL" of the DPC3 must NOT be inserted in any case. For example: sensitive employee data (such as health information severe disability appraisals sanctions during employment relationship) traffic data or customer password.
      <br><br><b>It is strictly prohibited to use the Translation Suite for the translation of VS-NfD Data (Verschlusssache – Nur für den Dienstgebrauch) civil servant data telecommunication secrecy or lawful interception data.</b>
      <br><br>You can find further information on the data protection classes in YAM under <a target="_blank" href="https://yam-united.telekom.com/pages/group-privacy/apps/wiki/wiki/list/view/3f68fe8d-6303-44c2-aa75-634805cf67ff?currentLanguage=EN">data protection class.</a> 
      <br><br>The TRANSLATION SUITE may only be used for professional or business purposes. 
      <br>TRANSLATION SUITE runs on Microsoft Azure Cognitive Services Translator that is a cloud-based machine translation service and is part of the Azure Cognitive Services family of cognitive APIs for building intelligent apps. Data submitted for translation to Azure Cognitive Services Translator are not written to persistent storage. There will be no record of the submitted text or any portion thereof in any Microsoft data center. The text will not be used for training purposes either. No cookies are stored in this service.
      <br><br><b>Terms and conditions</b>
      <br><br>1. Subject of Terms of Use - The following provisions define the terms for using the TRANSLATION SUITE platform. The platform includes translation service to a variety of DT-Group-relevant languages available for users to translate texts documents (ppt doc pdf txt) images (jpegjpgbmp) website and live screen (anything on the screen) of OPENINTERNAL and CONFIDENTIAL (up to DPC2) information protection classes. Users are employees of the Deutsche Telekom Group.
      <br><br>2. Purpose - The TRANSLATION SUITE may only be used for professional or business purposes. The Content made available by Deutsche Telekom shall be solely used for exercising/supporting an activity for the Deutsche Telekom Group.
      <br><br>3. Liability - Deutsche Telekom does not guarantee that the translation/translated information provided on this website is complete correct and up to date in all cases. Deutsche Telekom reserves the right to make changes or additions to the information provided or to remove it without prior notice. In no event shall Deutsche Telekom be liable.
      <br>Civil Servant Data: only business contact data of Civil Servants can be translated in the Translation Suite. for any damages whatsoever resulting from loss of use or data in connection with the use of information or the performance of services available from this website.
      <br><br>4. Confidentiality - The platform includes translation service to a variety of DT-Group-relevant languages available for users to translate texts documents (ppt doc pdf txt) images (jpegjpgbmp) website and live screen (anything on the screen) of OPEN INTERNAL and CONFIDENTAL (DPC2) information protection classes. Users are employees of the Deutsche Telekom Group. Users shall take all necessary measures to prevent third parties from obtaining Internal Information.
      <br><br>5. Modifications to Terms of Use - Deutsche Telekom shall be free to modify the terms of use at any time and to any extent whatsoever. Users must be notified of all modifications to the terms of use in writing or in text form including fax or e-mail. Modifications shall be binding for users from the time they receive such notification.
    </p>

    <p *ngIf="selectedLanguage == 'German'" class="justify-content"><b>Datenschutz</b>
      <br><br>UMGANG MIT DATEN IN DER TRANSLATION SUITE UND DATENSCHUTZKLASSEN. 
      <br>In der Translation Suite dürfen Sie Daten und Informationen der Datenschutzklassen "OFFEN" und "INTERN" ohne Einschränkungen übersetzen. 
      <br>Beispiel für Datenschutzklasse OFFEN (DSK0): anonymisierte Informationen Presseveröffentlichungen.
      <br>Beispiel für Datenschutzklasse INTERN (DSK1): dienstliche E-Mail-Adresse und Telefonnummer Organisationseinheit.
      <br><br>Bei Daten und Informationen der Datenschutzklasse "VERTRAULICH" ist zu unterscheiden: 
      <br><br>Sie dürfen die Translation Suite bis zur Datenschutzklasse 2 (DSK2) und nur für Aufgaben verwenden die gemäß dem DTSE Magenta Services Offshore Projekt bearbeitet werden dürfen. Beispiel für Datenschutzklasse Vertraulich DSK2: Adress-/Kontaktdaten von Vertragspartnern private Kontaktdaten von Beschäftigten Geburtsdatum Kundennummer.
      <br><br>Informationen Texte Dokumente Bilder die als "VERTRAULICH" der DSK3 eingestuft sind dürfen in keinem Fall übersetzt werden. Zum Beispiel: sensible Mitarbeiterdaten (wie Gesundheitsinformationen Schwerbehinderung Beurteilungen Sanktionen im Beschäftigungsverhältnis) Verkehrsdaten oder Kundenpasswörter.
      <br><br><b>Es ist strengstens untersagt die Translation Suite für die Übersetzung von VS-NfD-Daten (Verschlusssache – Nur für den Dienstgebrauch) Beamtendaten Telekommunikationsgeheimnis oder rechtmäßiger Überwachung zu verwenden.</b>
      <br><br>Nähere Informationen zu den Datenschutzklassen finden Sie in YAM unter <a target="_blank" href="https://yam-united.telekom.com/pages/group-privacy/apps/wiki/wiki/list/view/3f68fe8d-6303-44c2-aa75-634805cf67ff?currentLanguage=DE">Datenschutzklassen.</a>
      <br><br>Die TRANSLATION SUITE darf nur für berufliche oder geschäftliche Zwecke genutzt werden.
      <br>Die TRANSLATION SUITE wird auf Microsoft Azure Cognitive Services Translator ausgeführt einem cloudbasierten maschinellen Übersetzungsdienst der Teil der Azure Cognitive Services-Familie kognitiver APIs zum Erstellen intelligenter Apps ist. Daten die zur Übersetzung an Azure Cognitive Services Translator übermittelt werden werden nicht in den persistenten Speicher geschrieben. Es wird keine Aufzeichnung des übermittelten Textes oder eines Teils davon in einem Microsoft-Rechenzentrum geben. Der Text wird auch nicht für Schulungszwecke verwendet. In diesem Dienst werden keine Cookies gespeichert.
      <br><br><b>Allgemeine Geschäftsbedingungen</b>
      <br><br>1. Gegenstand der Nutzungsbedingungen - Die folgenden Bestimmungen definieren die Bedingungen für die Nutzung der TRANSLATION SUITE-Plattform. Die Plattform umfasst einen Übersetzungsservice in eine Vielzahl von DT-Group-relevanten Sprachen die den Nutzern zur Übersetzung von Texten Dokumenten (ppt doc pdf txt) Bildern (jpeg jpg bmp) Websites und Live-Bildschirmen (alles auf dem Bildschirm) der Datenschutzklassen OFFEN INTERN und VERTRAULICH (bis DSK2) zur Verfügung stehen. Nutzer sind Mitarbeiter des Konzerns Deutsche Telekom.
      <br><br>2. Zweck - Die TRANSLATION SUITE darf nur für berufliche oder geschäftliche Zwecke verwendet werden. Die von der Deutschen Telekom zur Verfügung gestellten Inhalte dürfen ausschließlich zur Ausübung bzw. Unterstützung einer Tätigkeit für den Konzern Deutsche Telekom verwendet werden.
      <br><br>3. Haftung - Die Deutsche Telekom übernimmt keine Gewähr dafür dass die auf dieser Website bereitgestellten Übersetzungen/Informationen in jedem Fall vollständig richtig und aktuell sind. Die Deutsche Telekom behält sich das Recht vor ohne vorherige Ankündigung Änderungen oder Ergänzungen der bereitgestellten Informationen vorzunehmen oder diese zu entfernen. In keinem Fall haftet die Deutsche Telekom für Schäden jeglicher Art die sich aus Nutzungs- oder Datenverlusten im Zusammenhang mit der Nutzung von Informationen oder der Erbringung von Diensten ergeben die auf dieser Website verfügbar sind.
      <br><br>4. Vertraulichkeit - Die Plattform umfasst einen Übersetzungsdienst in eine Vielzahl von DT-Group-relevanten Sprachen die den Nutzern zur Übersetzung von Texten Dokumenten (ppt doc pdf txt) Bildern (jpeg jpg bmp) Websites und Live-Bildschirmen (alles auf dem Bildschirm) der Datenschutzklassen OFFEN INTERN und VERTRAULICH (DSK2) zur Verfügung stehen. Nutzer sind Mitarbeiter des Konzerns Deutsche Telekom. Die Nutzer haben alle erforderlichen Maßnahmen zu ergreifen um zu verhindern dass Dritte an interne Informationen gelangen.
      <br><br>5. Änderungen der Nutzungsbedingungen - Der Deutschen Telekom steht es frei die Nutzungsbedingungen jederzeit und in jedem Umfang zu ändern. Änderungen der Nutzungsbedingungen sind dem Nutzer schriftlich oder in Textform auch per Fax oder E-Mail mitzuteilen. Änderungen sind für den Nutzer ab dem Zeitpunkt des Erhalts einer solchen Mitteilung verbindlich.
    </p>
   <scale-button slot="action" variant="secondary" (click)="closeModal()">
    Close
  </scale-button>
  </scale-modal>
</div>