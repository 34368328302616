import { Component } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage } from '@azure/msal-browser/dist/event/EventMessage';
import { EventType } from '@azure/msal-browser';
import { GRPADMIN_ROLE, SUPERADMIN_ROLE } from '../shared/data/common-data';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent {
  activeComponent: string = 'languages';
  showEnableLanguageSetting: boolean = false;
  showAppConfiguration: boolean = false;
  showDashboard: boolean = false;
  showGlossaryMenu: boolean = false;

  userRolesRaw: string[] = [];
  userRolesProcessed: string[] = [];

  superAdminRole = SUPERADMIN_ROLE;
  grpAdminRole = GRPADMIN_ROLE;

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msalAuthService: MsalService
  ) {}

  setActiveComponent(component: string) {
    this.activeComponent = component;
  }

  async ngOnInit() {
    this.msalBroadcastService.msalSubject$.subscribe((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        this.getUserRoles();
      }
    });

    this.getUserRoles(); // Initial roles fetch
  }

  // Retrieve roles from the token
  getUserRoles() {
    const account = this.msalAuthService.instance.getAllAccounts()[0]; // Assumes single account is logged in
    const idToken = account.idTokenClaims; // Access the ID token

    // Extract roles from the token (adjust depending on how roles are included in the token)
    if (idToken) {
      this.userRolesRaw = idToken.roles || []; // Roles claim is usually an array
      this.userRolesRaw.forEach(eachRole => {
        this.userRolesProcessed.push(eachRole.split('_')[1]);
      });
      this.showEnableLanguageSetting = this.hasRole(this.superAdminRole);
      this.showAppConfiguration = this.hasRole(this.superAdminRole);
      this.showDashboard = this.hasRole(this.grpAdminRole);
      this.showGlossaryMenu = this.hasRole(this.grpAdminRole);
    }
  }

  hasRole(role: string): boolean {
    return this.userRolesProcessed.includes(role);
  }

  /*  this.showEnableLanguageSetting = this.authService.isSettingsVisible();
    this.showAppConfiguration = this.authService.isConfigurationVisible();
    this.showDashboard = this.authService.isDashboardVisible();
    this.showGlossaryMenu = this.authService.isGlossaryMenuVisible(); */
}
