import { Component, AfterViewInit, Inject } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { EventMessage, EventType, InteractionType } from '@azure/msal-browser';
import {
  ALL_ACCESS_ROLE,
  GRPADMIN_ROLE,
  SUPERADMIN_ROLE,
} from '../shared/data/common-data';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements AfterViewInit {
  showSettings: boolean = false;
  userRolesRaw: string[] = [];
  userRolesProcessed: string[] = [];

  superAdminRole = SUPERADMIN_ROLE;
  grpAdminRole = GRPADMIN_ROLE;
  allAccessRole = ALL_ACCESS_ROLE;

  constructor(
    private router: Router,
    private msalAuthService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {}

  ngOnInit() {
    this.msalBroadcastService.msalSubject$.subscribe((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        this.getUserRoles();
      }
    });

    this.getUserRoles(); // Initial roles fetch
  }

  // Retrieve roles from the token
  getUserRoles() {
    const account = this.msalAuthService.instance.getAllAccounts()[0]; // Assumes single account is logged in
    const idTokenClaims = account.idTokenClaims; // Access the ID token

    // Extract roles from the token (adjust depending on how roles are included in the token)
    if (idTokenClaims) {
      this.userRolesRaw = idTokenClaims?.roles || []; // Roles claim is usually an array
      this.userRolesRaw.forEach(eachRole => {
        this.userRolesProcessed.push(eachRole?.split('_')[1]?.toLowerCase());
      });
      this.showSettings = this.hasRole();
    }
  }

  hasRole(): boolean {
    const result =
      this.userRolesProcessed.includes(this.superAdminRole) ||
      this.userRolesProcessed.includes(this.grpAdminRole);
    return result;
  }

  ngAfterViewInit(): void {}
  isMenuOpen = false;

  openMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  navigateToSettings() {
    if (this.showSettings) {
      this.router.navigate(['/settings']); // Navigate to settings route if user is an Admin
    } else {
      alert('You are not authorized to view this page.');
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalAuthService.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/',
      });
    } else {
      this.msalAuthService.logoutRedirect({
        postLogoutRedirectUri: '/',
      });
    }
  }
}
