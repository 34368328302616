<!-- <div class="setting-container" >
    <div class="setting-row" >
      <div class="setting-column" *ngIf="!loadLang">
        <h3>Add languages here</h3>
        <tag-input [ngModel]="selectedItems" [identifyBy]="'value'" [displayBy]="'display'"
                   (onAdd)="onItemAdded($event)" (onRemove)="onItemRemoved($event)" [onlyFromAutocomplete]="true">
          <tag-input-dropdown [showDropdownIfEmpty]="true" 
                              [autocompleteItems]="languages"></tag-input-dropdown>
        </tag-input>
      </div>
      <div class="setting-column"*ngIf="!loadLang">
        <h3>Data Retention period (in minutes)</h3>
        <input type="number" id="quantity" name="quantity" min="1" value="1" class="effect-1"><br><br>
      </div>
      <app-loading-spinner *ngIf="loadLang"></app-loading-spinner>
    </div>
  
    <div class="setting-row setting-center" *ngIf="!loadLang">
      <scale-button style="margin-left: 10px" type="submit" (click)="saveSelectedLanguages()"><app-loading-spinner></app-loading-spinner>Save</scale-button>
    </div>
    
  </div> -->
<form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
  <div class="grid-container">
    <h1 class="grid-header">
      Group Based Usage Report
      <scale-icon-alert-information
        accessibility-title="The start and end date are in UTC (Universal Timezone Coordinated)" />
    </h1>

    <div class="grid-start-date">
      <scale-date-picker
        label="Start Date (in UTC)"
        id="startDate"
        name="startDate"
        required
        helperText="Make sure to fill this"
        formControlName="startDate"
        ngDefaultControl
        class="form-control"
        (scale-change)="onDateChange('startDate', $event)"></scale-date-picker>
    </div>
    <div class="grid-end-date">
      <scale-date-picker
        label="End Date (in UTC)"
        id="endDate"
        name="endDate"
        value="2024-10-24"
        helperText="Make sure to fill this"
        required
        formControlName="endDate"
        class="form-control"
        ngDefaultControl
        (scale-change)="onDateChange('endDate', $event)"></scale-date-picker>
    </div>
    <div class="grid-info">
      The start and end date are in UTC (Universal Timezone Coordinated)
    </div>
    <div class="grid-submit">
      <scale-button type="submit">
        <app-loading-spinner></app-loading-spinner>View Report
      </scale-button>
    </div>
  </div>
</form>
<div *ngIf="successfulFetch">
  <scale-table show-sort>
    <table>
      <caption></caption>
      <thead>
        <tr>
          <!-- aria-sort can be descending or ascending -->
          <th
            tabindex="0"
            aria-sort="descending"
            (click)="sortTable('group')"
            id="group">
            Group Name
          </th>
          <th
            tabindex="0"
            onkeyup="handleKeyUp(event, 'consumption')"
            (click)="sortTable('consumption')"
            id="consumption">
            Group Usage (in %)
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let group of groupUsage | keyvalue">
          <td>{{ group.key }}</td>
          <td>{{ group.value }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>Total translation requests processed</td>
          <td>{{ totalHits }}</td>
        </tr>
      </tfoot>
    </table>
  </scale-table>
</div>
