import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  Observable,
  Subject,
  switchMap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { TextTranslationResponse } from '../shared/models/response/types';
import { NotificationService } from '../shared/services/notification.service';
import { Router } from '@angular/router';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-text-translate',
  templateUrl: './text-translate.component.html',
  styleUrls: ['./text-translate.component.css'],
})
export class TextTranslateComponent implements OnInit {
  @ViewChild('input') inputElement: any;
  @ViewChild('output') outputElement: any;
  @ViewChild('textTranslateForm') textTranslateForm!: NgForm;

  translatedText: string = '';
  response$: Observable<string> | undefined;

  sourceLangList: any[] = [];
  targetLangList: any[] = [];
  inputValue: string = '';
  outputValue: string = '';
  textareaDirty: boolean = false;
  sourceLang = { locale: '', name: '' };
  targetLang = { locale: '', name: '' };
  detectedLang = { locale: '', name: '' };
  showLanguageSwitch: boolean = false;

  searchTerm: string = '';
  private searchSubject: Subject<string> = new Subject<string>();
  http: any;

  constructor(
    private httpClient: HttpClient,
    private languageService: LanguageService,
    private NotificationService: NotificationService,
    private router: Router
  ) {
    this.searchSubject
      .pipe(
        debounceTime(500), // Wait for 500ms after the user stops typing
        distinctUntilChanged(), // Prevent making API calls for the same value
        filter((searchTerm: string) => searchTerm.trim().length > 2), // Optional: Only trigger when there is more than 1 word or content
        switchMap((searchTerm: string) => {
          const body = { text: searchTerm };
          return this.httpClient
            .post(`${environment.languageDetectionUrl}`, body)
            .pipe(
              catchError(error => {
                console.error('API Error', error);
                return []; // Return an empty array or handle error appropriately
              })
            );
        })
      )
      .subscribe(result => {
        // Handle the search results
        console.log('Search results:', result);
        const response: any = result;
        this.detectedLang = {
          name: response.primary_language.name,
          locale: response.primary_language.iso6391_name,
        };
        if (this.sourceLang.name == 'Auto-Detect') {
          this.sourceLang = {
            name: `${this.detectedLang.name}-Detected`,
            locale: this.detectedLang.locale,
          };
          this.showLanguageSwitch = true;
        }
        if (this.sourceLang.name.includes('Detected')) {
          this.sourceLang = {
            name: `${this.detectedLang.name}-Detected`,
            locale: this.detectedLang.locale,
          };
          this.showLanguageSwitch = true;
        }
      });
  }

  ngOnInit(): void {
    this.loadLanguages();
  }

  /*   compareObjects(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.locale === obj2.locale : obj1 === obj2;
  } */

  /*   onChange(eventData: any) {
    console.log('CHANGE EVENT DATA:', eventData);
  }
 */
  onReset() {
    this.inputValue = '';
    this.translatedText = '';
  }

  onInputChange() {
    this.searchSubject.next(this.searchTerm);
  }

  onSubmit() {
    if (!this.inputValue) {
      this.textareaDirty = true;
      return;
    } else {
      const body = {
        target_languages: [this.targetLang.locale],
        source_language: this.sourceLang.locale,
        text_to_translate: this.textTranslateForm.value.input,
      };

      this.httpClient
        .post<TextTranslationResponse>(environment.textTranslationUrl, body)
        .subscribe({
          next: (result: any) => {
            this.translatedText = result[0].text;
          },
          error: (err: any) => {
            if (err.status == 401) {
              this.NotificationService.showError(
                'Please login again ' + err.error.detail
              );
              this.router.navigate(['/']);
            } else {
              this.NotificationService.showError(err.error.detail);
            }
          },
        });
    }
  }

  onSelected(event: any) {}

  switchValues() {
    if (this.sourceLang.name.includes('Detected')) {
      this.sourceLang.name = this.sourceLang.name.split('-')[0];
    }
    let temp = this.sourceLang;
    this.sourceLang = this.targetLang;

    this.targetLang = temp;
  }

  loadLanguages() {
    this.languageService.getSavedLanguagesFromTranslationApi().subscribe(
      response => {
        //this.loadLang = false;
        // Assuming the response contains an array of saved languages
        const modifiedResponse: any[] = [
          {
            value: '', // Locale (Language code)
            display: 'Auto-Detect', // Language name
          },
          ...response[0].language,
        ];
        this.sourceLang = {
          locale: modifiedResponse[0].value,
          name: modifiedResponse[0].display,
        };
        this.targetLang = {
          locale: modifiedResponse[2].value,
          name: modifiedResponse[2].display,
        };
        this.sourceLangList = modifiedResponse.map((lang: any) => ({
          locale: lang.value, // Locale (Language code)
          name: lang.display, // Language name
        }));
        this.targetLangList = response[0].language.map((lang: any) => ({
          locale: lang.value, // Locale (Language code)
          name: lang.display, // Language name
        }));
        //this.dataRetentionPeriod = response.retentionPeriod; // Update the retention period from API
      },
      error => {
        //this.loadLang = false;
        console.error('Error fetching saved languages:', error);
      }
    );
  }

  copyToClipBoard() {
    const copyText = document.getElementsByClassName('copy-text')[0];
    copyText.classList.add('active');
    const copied = copyText.querySelector(
      'scale-textarea#output.form-control.hydrated'
    ) as HTMLInputElement;
    navigator.clipboard.writeText(copied.value);
    copyText.classList.add('active');
    setTimeout(function () {
      copyText.classList.remove('active');
    }, 2500);
  }

  onSourceLangChange(newSourceLang: { locale: string; name: string }) {
    this.sourceLang = newSourceLang;
  }
}
