<div class="translator-card">
  <div class="text-grid-container">
    <form #docTranslateForm="ngForm" (ngSubmit)="onSubmit()">
      <app-language-selector
        [sourceLangList]="sourceLangList"
        [targetLangList]="targetLangList"
        [sourceLang]="sourceLang"
        [targetLang]="targetLang"
        (sourceLangChange)="onSourceLangChange($event)"
        (targetLangChange)="onTargetLangChange($event)"
        (switchValues)="switchValues()"
        aria-label="Language selection"
        class="">
      </app-language-selector>

      <div class="grid-row">
        <div class="grid-column">
          <div>
            <p>
              Upload the document(s) to be translated.
              <br />
              <i class="h12"
                >The supported formats are .ppt .pptx .doc .docx .xls .xlsx
                .pdf</i
              >
            </p>

            <!-- File Input Section -->
            <input
              type="file"
              id="actual-btn"
              hidden
              (change)="onFileSelected($event)"
              #fileUpload
              multiple
              aria-labelledby="file-chosen"
              aria-describedby="file-types" />

            <!-- Custom Upload Button -->
            <label
              for="actual-btn"
              class="custom-upload-btn"
              id="actual-btn-label" role="button"
              tabindex="0"  (keydown)="handleFileUploadKeydown($event)" ><b>Choose File</b></label
            >

            <span
              id="file-chosen"
              [title]="fileNameTooltip"
              aria-live="polite"
              >{{ truncatedFileName }}</span
            >
            <scale-icon-action-remove
              accessibility-title="Remove selected files"
              class="remove"
              selected
              size="17"
              *ngIf="this.files.length > 0"
              (click)="clearFiles(fileUpload)" role="button"  tabindex="0" (keydown.enter)="clearFiles(fileUpload)" (keydown.space)="clearFiles(fileUpload)">
            </scale-icon-action-remove>
          </div>
          <div class="select-container" *ngIf="glossaryList.length > 0">
            <!-- Link to view and select glossary -->
            <a
              class="glossary-list"
              (click)="openGlossaryDialog()"
              *ngIf="!userAppliedglossary"
              role="button">
              <scale-icon-user-file-contracts
                accessibility-title="contracts" /><span
                class="glossary-link-text"
                style="display: inline-flex">
                View and select glossary</span
              >
            </a>

            <!-- Display filename and change/remove links when a glossary is applied -->
            <div *ngIf="userAppliedglossary" class="selected-glossary">
              <span class="glossary-file-name">{{ userAppliedglossary }}</span>
              <span class="glossary-actions">
                <scale-icon-action-edit
                  accessibility-title="edit"
                  (click)="openGlossaryDialog()"
                  class="action-icon"
                  title="Change" />
                <scale-icon-action-remove
                  accessibility-title="remove"
                  (click)="userAppliedglossary = ''"
                  class="action-icon"
                  title="Remove" />
              </span>
            </div>
          </div>
          <!-- Error Messages -->
          <div *ngIf="fileRequiredError" class="error-message" role="alert">
            {{ errorMessage }}
          </div>
          <div *ngIf="fileTypeError" class="error-message" role="alert">
            {{ errorMessage }}
          </div>
          <div *ngIf="fileSizeError" class="error-message" role="alert">
            {{ errorMessage }}
          </div>
          <div *ngIf="filesNameError" class="error-message" role="alert">
            {{ errorMessage }}
          </div>
          <div *ngIf="filesNumberError" class="error-message" role="alert">
            {{ errorMessage }}
          </div>
        </div>

        <div class="grid-column"></div>

        <div class="grid-column">
          <div class="container">
            <table>
              <tr>
                <p class="element">Translate Embedded Images?</p>
                <label for="translate-images" class="element-label">No</label>

                <!-- Switch Component for Translate Embedded Images -->
                <scale-switch
                  id="translate-images"
                  style="float: left; margin-top: 15px; font-weight: 500"
                  size="small"
                  label="Yes"
                  aria-label="Translate embedded images"
                  title="Translate Embedded Images?"
                  (change)="switchImageQuality()"
                  [checked]="translateEmbeddedImages">
                </scale-switch>
              </tr>

              <tr class="element">
                <scale-button
                  style="margin-left: 10px"
                  type="submit"
                  [disabled]="!files.length"
                  aria-label="Translate document"
                  [attr.aria-disabled]="!files.length ? true : null">
                  <app-loading-spinner></app-loading-spinner> Translate
                </scale-button>

                <a
                  *ngIf="downloadLinks.length >= 1"
                  [href]="downloadLinks[0].link"
                  [download]="downloadLinks[0].name"
                  class="download"
                  aria-label="Download translated document" tabindex="0">
                  Download Translated Document
                </a>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="grid-row-table">
        <div>
          <scale-table
            *ngIf="fileStatuses.length > 0 || statusError"
            class="centered-table scrollable-table">
            <table>
              <thead>
                <tr>
                  <th>Document Batch ID</th>
                  <th>Status</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody *ngIf="!statusError">
                <tr *ngFor="let file of fileStatuses">
                  <td>{{ file.batch_id }}</td>
                  <td>
                    <scale-tag
                      *ngIf="file.status === 'failed'"
                      size="small"
                      color="red"
                      >{{ file.status }}</scale-tag
                    >
                    <scale-tag
                      *ngIf="file.status === 'success'"
                      size="small"
                      color="green"
                      >{{ file.status }}</scale-tag
                    >
                    <scale-tag
                      *ngIf="
                        file.status !== 'failed' && file.status !== 'success'
                      "
                      size="small"
                      >{{ file.status }}</scale-tag
                    >
                  </td>
                  <td>
                    <scale-icon-action-download-from-cloud
                      accessibility-title="Download file"
                      [ngClass]="{ 'magenta-icon': file.status === 'success' }"
                      [attr.disabled]="file.status !== 'success' ? true : null"
                      (click)="
                        file.status === 'success' &&
                          downloadfile(file.zip_file_path, file.zip_file_name)
                      ">
                    </scale-icon-action-download-from-cloud>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="statusError">
                <tr>
                  <td colspan="4" class="error-msg" role="alert">
                    <scale-icon-action-circle-close
                      accessibility-title="error-circle"
                      size="12" />
                    Failed to retrieve the status of the batch document
                    <span *ngIf="errorDetail">: {{ errorDetail }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </scale-table>
        </div>
      </div>
    </form>
  </div>
</div>

<div *ngIf="showGlossory">
  <scale-modal
    heading="Select a Glossary"
    size="large"
    [opened]="showGlossory"
    omit-close-button="true">
    <div class="glossary-view">
      <!-- Glossary Selector -->
      <ng-select
        placeholder="Select glossary"
        [items]="glossaryList"
        bindLabel="glossary_name"
        notFoundText="No matching glossary found"
        [(ngModel)]="selectedGlossary"
        (change)="onGlossarySelect($event)"
        [clearable]="false">
      </ng-select>

      <div *ngIf="selectedGlossary">
        <p><b>Description:&nbsp;</b>{{ selectedGlossary.description }}</p>
        <p><b>Created by:&nbsp;</b>{{ selectedGlossary.created_by }}</p>
        <p>
          <b>Created on:&nbsp;</b
          >{{ selectedGlossary.created_on | date: 'dd-MM-yyyy' }}
        </p>
      </div>
      <!-- Table Displaying Selected Glossary Content -->
      <scale-table *ngIf="selectedGlossary">
        <table>
          <thead>
            <tr>
              <th>Key ({{ selectedGlossary.source_lang }})</th>
              <th>Value ({{ selectedGlossary.target_lang }})</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of selectedGlossary.file_content">
              <td>{{ getKey(item) }}</td>
              <!-- Extract key -->
              <td>{{ getValue(item) }}</td>
              <!-- Extract value -->
            </tr>
          </tbody>
        </table>
      </scale-table>
    </div>
    <scale-button
      slot="action"
      variant="primary"
      (click)="selectGlosssary(selectedGlossary)"
      >Apply</scale-button
    >
    <scale-button slot="action" variant="secondary" (click)="closeModal()"
      >Close</scale-button
    >
  </scale-modal>
</div>
