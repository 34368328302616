<div class="setting-container" >
    <div class="setting-row" >
      <div class="setting-column" *ngIf="!loadLang">
        <h3>Enabled languages</h3>
        <tag-input [ngModel]="selectedItems" [identifyBy]="'value'" [displayBy]="'display'"
                   (onAdd)="onItemAdded($event)" (onRemove)="onItemRemoved($event)" [onlyFromAutocomplete]="true" secondaryPlaceholder="Search language" placeholder="+add more languages">
          <tag-input-dropdown [showDropdownIfEmpty]="true" 
                              [autocompleteItems]="languages"></tag-input-dropdown>
        </tag-input>
      </div>
      
    </div>
  
    <div class="setting-row setting-center" *ngIf="!loadLang">
      <scale-button  type="submit" (click)="saveSelectedLanguages()"><app-loading-spinner></app-loading-spinner>Save</scale-button>
    </div>
    <app-loading-spinner *ngIf="loadLang"></app-loading-spinner>
  </div>