import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { MsalGuard } from '@azure/msal-angular';
import { SettingsComponent } from './settings/settings.component';
import { SUPERADMIN_ROLE, GRPADMIN_ROLE } from './shared/data/common-data';

const routes: Routes = [
  {
    path: 'translate',
    pathMatch: 'full',
    canActivate: [MsalGuard],
    component: HomeComponent,
  },
  { path: '', pathMatch: 'full', component: LoginComponent },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [MsalGuard, AuthGuard], // Protect route
    data: { roles: [SUPERADMIN_ROLE, GRPADMIN_ROLE] }, // Allowed roles
  },
  // otherwise redirect to home
  //{ path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppRoutingModule {}
