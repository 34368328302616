import { Component } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent {
  activeComponent: string = 'languages';
  showEnableLanguageSetting: boolean = false;
  showAppConfiguration: boolean = false;
  showDashboard: boolean = false;
  showGlossaryMenu: boolean = false;

  constructor(private authService: AuthenticationService) {}

  setActiveComponent(component: string) {
    this.activeComponent = component;
  }

  async ngOnInit() {
    this.showEnableLanguageSetting = this.authService.isSettingsVisible();
    this.showAppConfiguration = this.authService.isShowConfiguration();
    this.showDashboard = this.authService.isShowDashboard();
    this.showGlossaryMenu = this.authService.isShowGlossaryMenu();
  }
}
