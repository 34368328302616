import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

/* @Injectable({
  providedIn: 'root',
}) */

/* 
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: MsalService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const permittedRoles = route.data['roles'] as Array<string>;
    const account = this.authService.instance.getAllAccounts()[0]; // assuming single account logged in
    const idTokenClaims = account?.idTokenClaims;
    if (idTokenClaims && idTokenClaims.roles) {
      const userRoles = idTokenClaims.roles as Array<string>;
      // Check if any of the permitted roles match the roles in the ID token
      return permittedRoles.some(role => userRoles.includes(role));
    } else {
      alert('You do not have permission to access this page.');
      this.router.navigate(['/translate']); // Redirect to home or unauthorized page
      return false;
    }
  } */
export const AuthGuard: CanActivateFn = route => {
  const authService = inject(MsalService);
  const router = inject(Router);

  const permittedRoles = route.data['roles'] as Array<string>;
  const account = authService.instance.getAllAccounts()[0]; // assuming single account logged in
  const idTokenClaims = account?.idTokenClaims;

  if (idTokenClaims && idTokenClaims.roles) {
    const userRoles = idTokenClaims.roles as Array<string>;
    // Check if any of the permitted roles match the roles in the ID token
    return permittedRoles.some(role => userRoles.includes(role));
  } else {
    alert('You do not have permission to access this page.');
    router.navigate(['/translate']); // Redirect to home or unauthorized page
    return false;
  }
};
/*  return false;
    if (!hasRole) {
      alert('You do not have permission to access this page.');
      this.router.navigate(['/translate']); // Redirect to home or unauthorized page
      return false;
    } else {
      return true;
    } */

// Wait for the token to be acquired and roles to be set
/*  if(this.authService.userRole){}
    const token = await this.authService.acquireToken();

    // Check if the user has the required 
    if(token){
      const hasRole = expectedRoles.some(role => this.authService.hasRole(role));
      if (!hasRole) {
        alert('You do not have permission to access this page.');
        this.router.navigate(['/']); // Redirect to home or unauthorized page
        return false;
      }
    }
    
    return true; */
