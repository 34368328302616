import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../shared/services/authentication.service';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  isAgreed = false;
  emailValue: string = '';
  passwordValue: string = '';
  submitted = false;
  showTermsAndConditions = false
  form = {
    username: '',
    password: '',
  };
  selectedLanguage: string = 'English'; // Default language
  languages = ['English', 'German'];
  @ViewChild('loginForm') loginForm!: NgForm;

  constructor(
    private router: Router,
    // private authService: AuthenticationService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,private cdr: ChangeDetectorRef
  ) {}

  /*  onSubmit(){
    if (this.loginForm.invalid) {
      return;
    }
    this.submitted = true
    const { username, password } = this.form;
    this.authService.login(this.emailValue, this.passwordValue).subscribe(
      (next: any) => {
        // Redirect to home or any other desired route
        
      },
      (complete: any) => {
        // Redirect to home or any other desired route
        
      },
      (error: void) => {
        // Handle error (e.g., display error message)
      }
    );
  } */
    onLanguageChange(language: any) {
      this.selectedLanguage = language;
    }

    loadTermsAndConditions () {
        this.showTermsAndConditions = !this.showTermsAndConditions
    }

    closeModal(){
      this.showTermsAndConditions = false
    }

    loginRedirect() {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          redirectStartPage: '/translate',
        } as RedirectRequest);
        /* .subscribe(() => {
            debugger;
            // Redirect to /translation route after successful login
            this.router.navigate(['/translate']);
          }); */
      } else {
        this.authService.loginRedirect(); /* .subscribe(() => {
          debugger;
          // Redirect to /translation route after successful login
          this.router.navigate(['/translate']);
        }); */
      }
    }
}
