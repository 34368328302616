export const environment = {
  production: false,
  xClientId: '47e1dc5c-9d0e-4098-8c3a-1a9e2dbe5da8',
  authUrl: 'https://dev.translate.dtse.telekom.net/text/authenticate',
  textTranslationUrl: 'https://dev.translate.dtse.telekom.net/text',
  documentTranslationUrl: 'https://dev.translate.dtse.telekom.net/document',
  imageSingleFileUrl:
    'https://dev.translate.dtse.telekom.net/image/single_file',
  save_glossary:
    'https://dev.translate.dtse.telekom.net/utility/configuration/upload_glossary',
  download_glossary:
    'https://dev.translate.dtse.telekom.net/utility/configuration/download_glossary_admin',
  delete_glossary:
    'https://dev.translate.dtse.telekom.net/utility/configuration/delete_glossary',
  get_admin_glossary:
    'https://dev.translate.dtse.telekom.net/utility/configuration/get_glossary_admin',
  get_user_glossary:
    'https://dev.translate.dtse.telekom.net/utility/configuration/get_glossary_user',
  save_retention:
    'https://dev.translate.dtse.telekom.net/utility/configuration/retention_period',
  get_retention:
    'https://dev.translate.dtse.telekom.net/utility/configuration/get_retention_period',
  Savelanguage:
    'https://dev.translate.dtse.telekom.net/utility/configuration/language',
  Getlanguage:
    'https://dev.translate.dtse.telekom.net/utility/configuration/get_language',
  imageBatchFileUrl: 'https://dev.translate.dtse.telekom.net/image/batch_file',
  imageHeartbeatUrl: 'https://dev.translate.dtse.telekom.net/image',
  statusapi: 'https://dev.translate.dtse.telekom.net/utility/status',
  downloadapi: 'https://dev.translate.dtse.telekom.net/utility/download',
  reportsUrl: 'https://dev.translate.dtse.telekom.net/utility/report',
  languageDetectionUrl:
    'https://dev.translate.dtse.telekom.net/utility/detect_language',
  name: 'local',
  msalConfig: {
    auth: {
      clientId: '540c5a25-debc-4586-bc4f-19df07817699', //c390864a-85a5-41ca-8973-373e52363c3d',
      //'e0b209a2-e80c-4876-8704-755cf6adc23a', //'b04c5f70-ea91-4727-8a66-869b23c70dca',
      authority:
        'https://login.microsoftonline.com/a5e6c6bd-17f0-4980-be25-49bcd5c558ae',
      //90725bd0-98a6-4fb4-be90-3095fdcd15e0',
      // 90725bd0-98a6-4fb4-be90-3095fdcd15e0', //628242bd-7e70-4aa9-8ee1-72586b4540fe',
      redirectUri: 'https://dev.translate.dtse.telekom.net', //'http://localhost:4200/translate',
      //navigateToLoginRequestUrl: false,
      cache: {
        cacheLocation: 'sessionStorage', // Default is sessionStorage
        storeAuthStateInCookie: true,
      },
    },
    apiConfig: {
      scopes: ['api://translation-api/roles'], // ['api://523cc4f6-5cc5-4084-b4a7-2687087dae95/Files.Read'], //['user.read'],
      uri: /* 'https://graph.microsoft.com/v1.0/me' */ 'https://dev.translate.dtse.telekom.net',
    },
  },
};
