<div class="glossary-container" >
  
    


    <scale-tab-nav size="large">
     
      <scale-tab-header slot="tab">
        <scale-icon-user-file-html-file
          accessibility-title="html-file"
          selected />
        Create Glossary
      </scale-tab-header>
      <scale-tab-panel slot="panel">
        <div class="setting-row" >
          <div class="setting-column setting-column-source" *ngIf="!loadLang">
            <h3>Source Language</h3>
            
          </div>
          <div class="setting-column setting-column-target" *ngIf="!loadLang">
            <h3>Target Language </h3>
            
          </div>
        </div>
        <div class="setting-row" >
          <!-- <div class="setting-column">
            <h3>Source</h3>
            
          </div> -->
          <div class="setting-column" *ngIf="!loadLang">
            <app-language-selector
            [cities]="cities"
              [sourceLang]="sourceLang"
              [targetLang]="targetLang"
              (sourceLangChange)="sourceLang = $event"
              (targetLangChange)="targetLang = $event"
              [admin]="true"
              class="grid-row">
          </app-language-selector>
            
          </div>
          <!-- <div class="setting-column">
            <h3>Target language</h3>
            <input type="text" id="quantity" name="quantity"  class="effect-1">
          </div> -->
      
          
          <!-- <app-loading-spinner *ngIf="loadLang"></app-loading-spinner> -->
        </div>
        <div class="setting-row" >
          <div class="setting-column" *ngIf="!loadLang">
            <h3>Glossary Name <span aria-hidden="true" class="required">*</span></h3>
            <input type="text" id="glossaryName" name="glossaryName" class="effect-1" [(ngModel)]="glossaryName" (ngModelChange)="validateForm()" required>
            <div *ngIf="GlossaryNameRequiredError" class="error-message">Glossary Name is required</div>
          </div>
          
          <div class="setting-column" style="padding-left: 40px;" *ngIf="!loadLang">
            <h3>Description <span aria-hidden="true" class="required">*</span></h3>
            <input type="text" id="description" name="description" class="effect-1" [(ngModel)]="glossaryDescription" (ngModelChange)="validateForm()" required>
            <div *ngIf="GlossaryDescriptionRequiredError" class="error-message">Description is required</div>
          </div>
        </div>
          <div class="setting-row ">
            <div class="setting-column" *ngIf="!loadLang">
              <p>
                Upload the glossary document <br />
                <i class="h12">The supported formats are .csv</i>
              </p>
              <input
                type="file"
                class="file-upload"
                #fileUpload
                (change)="validateFiles(fileUpload)"
                required />
              <div *ngIf="fileRequiredError" class="error-message">File is required</div>
              <div *ngIf="fileTypeError" class="error-message">Only .csv files are allowed</div>
            </div>
      
            <div class="setting-column" *ngIf="!loadLang">
              <scale-button style="margin-left: 10px" type="submit" (click)="onSave()">Save</scale-button>
            </div>
            
          </div>
      </scale-tab-panel>
     
  
      <scale-tab-header slot="tab">
        <scale-icon-user-file-file-collection
          accessibility-title="file-collection"
          selected />
        View Glossary(s)
      </scale-tab-header>
      <scale-tab-panel slot="panel">
        <div class="setting-row setting-center" style="margin-top: 30px;" *ngIf="glossaryList.length>0">
          <scale-table >
            <table>
              <thead>
                <tr>
                  <th id="title">File name</th>
                  <th>Source language</th>
                  <th>Target language</th>
                  <th>Description</th>
                  <th>Created by</th>
                  <th>Created date</th>
                 
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let glossary of glossaryList">
                  <td>{{ glossary.file_name }}</td>
                  <td>{{ glossary.source_lang | uppercase}}</td>
                  <td>{{ glossary.target_lang | uppercase}}</td>
                  <td>{{ glossary.description }}</td>
                  <td>{{ glossary.created_by }}</td>
                  <td>{{ glossary.created_on | date: 'yyyy-MM-dd' }}</td>   
                  <td>
                    <a class="glossary-list" (click)="downloadGlossary(glossary)">Download</a> | 
                    <a class="glossary-list" (click)="deleteGlossary(glossary)">Delete</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </scale-table>
          
        </div>
      </scale-tab-panel>
    </scale-tab-nav>
    <app-loading-spinner *ngIf="loadLang"></app-loading-spinner>
</div>