
<scale-telekom-header>
  <scale-telekom-nav-list slot="main-nav" aria-label="Main Navigation Links">
    <scale-telekom-nav-item aria-label="Translation Suite">
      <a >Translation Suite</a>
    </scale-telekom-nav-item>
   
    
  </scale-telekom-nav-list>
  <scale-telekom-nav-list variant="functions" slot="functions" alignment="right" aria-label="Profile Menu" role="none">
    <scale-telekom-nav-item >
      <scale-menu-flyout>
      <a href="javascript:void(0);" slot="trigger"  variant="secondary" icon-only  class="username">
        <scale-badge no-dot="true" >
        <scale-icon-user-file-user size="24"  style="display: inline-flex;"  accessibility-title=""  aria-label="Profile">
        
        
        
        
        </scale-icon-user-file-user>
        <div slot="dot" class="mydot">
        <scale-icon-action-checkmark size="24"  style="display: inline-flex;">
        </scale-icon-action-checkmark>
        </div>
        </scale-badge>
        <!-- <span class="flyout-label">Alexander</span> -->
        </a>

      <scale-menu-flyout-list >
        <scale-menu-flyout-item class="outline" *ngIf="showSettings" class="outline" (click)="navigateToSettings()">
          <scale-icon-service-settings accessibility-title="settings" size="6" />
          <span>Setting</span>
        </scale-menu-flyout-item>
        <scale-menu-flyout-item class="outline" (click)="logout()">
          <scale-icon-action-logout accessibility-title="logout" size="6" />
          <span>Logout</span>
        </scale-menu-flyout-item>
      </scale-menu-flyout-list>
      </scale-menu-flyout>

    </scale-telekom-nav-item>
  </scale-telekom-nav-list>

</scale-telekom-header>
