import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LanguageService } from '../language.service';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.css'],
})
export class GlossaryComponent implements OnInit {
  sourceLangList: any[] = [];
  targetLangList: any[] = [];
  glossaryList: any = [];
  sourceLang = { locale: '', name: '' }; // Default values
  targetLang = { locale: '', name: '' }; // Default values
  loadLang = false;
  glossaryName: string = ''; // Glossary name
  glossaryDescription: string = ''; // Glossary description
  selectedFile: File | null = null; // To store selected file

  GlossaryNameRequiredError: boolean = false;
  GlossaryDescriptionRequiredError: boolean = false;
  fileRequiredError: boolean = false;
  fileTypeError: boolean = false;
  fileNameTooltip: string = 'No file chosen';
  truncatedFileName: string = 'No file chosen';
  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadLanguages();
    this.getGlossaryList();
  }

  loadLanguages() {
    this.loadLang = true;
    this.languageService.getSavedLanguagesFromTranslationApi().subscribe(
      response => {
        //this.loadLang = false;
        // Assuming the response contains an array of saved languages
        //this.loadLang = false;
        // Assuming the response contains an array of saved languages
        const modifiedResponse: any[] = [...response[0].language];
        this.sourceLang = {
          locale: modifiedResponse[0].value,
          name: modifiedResponse[0].display,
        };
        this.targetLang = {
          locale: modifiedResponse[2].value,
          name: modifiedResponse[2].display,
        };
        this.sourceLangList = response[0].language.map((lang: any) => ({
          locale: lang.value, // Locale (Language code)
          name: lang.display, // Language name
        }));
        this.targetLangList = response[0].language.map((lang: any) => ({
          locale: lang.value, // Locale (Language code)
          name: lang.display, // Language name
        }));
        this.getGlossaryList();
      },
      error => {
        this.loadLang = false;
        console.error('Error fetching saved languages:', error);
      }
    );
  }

  // Validate form inputs
  validateForm(): boolean {
    this.GlossaryNameRequiredError = !this.glossaryName.trim();
    this.GlossaryDescriptionRequiredError = !this.glossaryDescription.trim();

    return (
      !this.GlossaryNameRequiredError && !this.GlossaryDescriptionRequiredError
    );
  }

  // Validate file input
  validateFiles(fileInput: any): boolean {
    const file = fileInput.files[0];
    if (!file) {
      this.fileRequiredError = true;
      this.fileTypeError = false;
      this.fileNameTooltip = 'No file chosen';
      this.truncatedFileName = 'No file chosen';
    } else {
      this.fileRequiredError = false;
      const fileType = file.name.split('.').pop().toLowerCase();
      this.fileTypeError = fileType !== 'csv';
      this.truncatedFileName = this.truncateFileName(file.name, 20);
    }

    this.selectedFile = file; // Store the selected file
    return !this.fileRequiredError && !this.fileTypeError;
  }

  // Handle the save action
  onSave(): void {
    const isFormValid = this.validateForm();
    const isFileValid = this.selectedFile && !this.fileTypeError;

    if (isFormValid && isFileValid) {
      this.uploadGlossary();
    } else {
      console.log('Form is invalid or file is missing.');
    }
  }

  // Upload glossary data along with the file
  uploadGlossary(): void {
    this.loadLang = true;
    const formData = new FormData();

    // Prepare the glossary request as a plain JSON string (without converting to a Blob)
    const glossaryRequest = {
      glossary_name: this.glossaryName,
      description: this.glossaryDescription,
      source_language: this.sourceLang.locale,
      target_language: this.targetLang.locale,
    };

    // Append glossary_request as a JSON string
    formData.append('glossary_request', JSON.stringify(glossaryRequest));

    // Append the file, even if empty
    if (this.selectedFile) {
      formData.append('file', this.selectedFile, this.selectedFile.name);
    }

    const headers = new HttpHeaders({
      // Let Angular handle Content-Type and boundary for multipart/form-data
      // 'Authorization': 'Bearer your-auth-token' // Uncomment if you need authorization
    });

    // Call the backend API
    this.http.post(environment.save_glossary, formData, { headers }).subscribe(
      response => {
        this.loadLang = false;
        this.notificationService.showSuccess('Upload successful');
        console.log('Upload successful', response);
        this.getGlossaryList();
        // Handle success (e.g., show a success message or redirect)
      },
      error => {
        this.loadLang = false;
        console.error('Upload failed', error);
        this.notificationService.showError('Upload failed');
        // Handle errors (e.g., show an error message)
      }
    );
  }
  private truncateFileName(fileName: string, maxLength: number): string {
    if (fileName.length <= maxLength) {
      return fileName;
    }
    const extensionIndex = fileName.lastIndexOf('.');
    const extension = extensionIndex > -1 ? fileName.substring(extensionIndex) : '';
    const truncatedName = fileName.substring(0, maxLength - extension.length - 3); // 3 for "..."
    return `${truncatedName}...${extension}`;
  }
  getGlossaryList(): void {
    this.loadLang = true;
    const headers = new HttpHeaders({
      // Add authorization headers or other headers if necessary
      // 'Authorization': 'Bearer your-auth-token',
    });

    // Make the GET request to fetch the glossary list
    this.http.get(environment.get_admin_glossary, { headers }).subscribe(
      response => {
        this.loadLang = false;
        // this.notificationService.showError('Glossary list fetched successfully');
        // Handle the response, maybe store it in a variable to display on the UI
        this.glossaryList = response;
      },
      error => {
        this.loadLang = false;
        this.notificationService.showError('Failed to fetch glossary list');
        // Handle the error, display error messages if needed
      }
    );
  }

  downloadGlossary(glossary: any) {
    const payload = {
      filename: glossary.file_name,
      filepath: glossary.file_path,
    };

    this.http
      .post(environment.download_glossary, payload, { responseType: 'blob' })
      .subscribe({
        next: (blob: Blob) => {
          const downloadUrl = window.URL.createObjectURL(blob);
          const downloadLink = document.createElement('a');
          downloadLink.href = downloadUrl;
          downloadLink.download = glossary.file_name;
          downloadLink.click();
          window.URL.revokeObjectURL(downloadUrl);
        },
        error: error => this.notificationService.showError('Download failed'),
      });
  }

  deleteGlossary(glossary: any) {
    const payload = { glossary_id: glossary.id };

    this.http.delete(environment.delete_glossary, { body: payload }).subscribe({
      next: () => {
        // Refresh the glossary list or remove the deleted item
        //this.glossaryList = this.glossaryList.filter((item: { id: any; }) => item.id !== glossary.id);
        this.notificationService.showSuccess('Deleted succesfully');
        this.getGlossaryList();
      },
      error: error => this.notificationService.showError('Deleted failed'),
    });
  }
}
