<div class="setting-container" role="region" aria-labelledby="enabled-languages-section">
  <!-- Enabled Languages Section -->
  <div class="setting-row">
    <div class="setting-column" *ngIf="!loadLang">
      <h3 id="enabled-languages-section">Enabled languages</h3>
      <!-- Tag Input for Language Selection -->
      <tag-input 
        [ngModel]="selectedItems" 
        [identifyBy]="'value'" 
        [displayBy]="'display'"
        (onAdd)="onItemAdded($event)" 
        (onRemove)="onItemRemoved($event)" 
        [onlyFromAutocomplete]="true" 
        secondaryPlaceholder="Search language" 
        placeholder="+add more languages" 
        aria-label="Add or remove enabled languages" 
        aria-describedby="language-selection-instructions">
        <tag-input-dropdown 
          [showDropdownIfEmpty]="true" 
          [autocompleteItems]="languages">
        </tag-input-dropdown>
      </tag-input>
      
    </div>
  </div>

  <!-- Save Button -->
  <div class="setting-row setting-center" *ngIf="!loadLang">
    <scale-button 
      type="submit" 
      (click)="saveSelectedLanguages()" 
      aria-label="Save selected languages">
      <app-loading-spinner aria-hidden="true"></app-loading-spinner>
      Save
    </scale-button>
  </div>

  <!-- Loading Spinner -->
  <app-loading-spinner *ngIf="loadLang" aria-label="Loading languages, please wait"></app-loading-spinner>
</div>
