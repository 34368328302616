import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { UploadService } from '../shared/services/upload.service';
import {
  MAX_SINGLE_DOCUMENT_FILE_SIZE_BYTES,
  MAX_TOTAL_DOCUMENT_FILE_SIZE_BYTES,
  SupportedLanguages,
  invalidPattern,
  doumentExtensionSupport,
} from '../shared/data/common-data';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../shared/services/notification.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-document-translate',
  templateUrl: './document-translate.component.html',
  styleUrls: ['./document-translate.component.css'],
})
export class DocumentTranslateComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileUpload?: ElementRef;
  showGlossory = false;
  files: File[] = [];
  fileNames: string[] = [];
  supportedLanguages = SupportedLanguages;
  doumentExtensionSupport = doumentExtensionSupport;
  downloadLinks: { link: string; name: string }[] = [];
  isLoading: boolean = false;
  statusError: Boolean = false;
  errorDetail: string = '';
  fileNameTooltip: string = 'No file chosen';
  truncatedFileName: string = 'No file chosen';

  sourceLangList: any = [];
  targetLangList: any = [];
  sourceLang = { locale: '', name: '' };
  targetLang = { locale: '', name: '' };
  translatedFileNames: string[] = [];
  glossaryList: any = [];
  // glossaryList: any[] = [];
  // selectedGlossary: any = null;
  selectedGlossaryData: any[] = [];
  userAppliedglossary: string = '';
  userAppliedglossaryFilePath: string = '';
  translateEmbeddedImages = false;
  fileRequiredError: boolean = false;
  fileTypeError: boolean = false;
  fileSizeError: boolean = false;
  filesNumberError: boolean = false;
  filesNameError: boolean = false;
  errorMessage: string = '';
  fileStatuses: {
    zip_file_name: string;
    status: string;
    zip_file_path: string;
    date: string;
    batch_id: string;
  }[] = [];
  selectedGlossary: {
    created_by: string;
    created_on: string;
    description: string;
    file_content: { [key: string]: string }[]; // Array of key-value pairs
    file_name: string;
    file_path: string;
    glossary_name: string;
    group_id: string;
    id: string;
    source_lang: string;
    target_lang: string;
    updated: string;
    updated_by: string;
    user_oid: string;
  } | null = null;
  private statusInterval: any;

  constructor(
    private uploadService: UploadService,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private httpClient: HttpClient,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.loadLanguages();
    this.fetchFileStatuses();
    this.statusInterval = setInterval(() => {
      this.fetchFileStatuses();
    }, 5000); // 15 seconds
  }

  ngOnDestroy(): void {
    if (this.statusInterval) {
      clearInterval(this.statusInterval);
    }
  }

  /*   compareObjects(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.locale === obj2.locale : obj1 === obj2;
  } */

  loadLanguages() {
    this.languageService.getSavedLanguagesFromTranslationApi().subscribe(
      response => {
        //this.loadLang = false;
        // Assuming the response contains an array of saved languages
        const modifiedResponse: any[] = [
          {
            value: '', // Locale (Language code)
            display: 'Auto-Detect', // Language name
          },
          ...response[0].language,
        ];
        this.sourceLang = {
          locale: modifiedResponse[0].value,
          name: modifiedResponse[0].display,
        };
        this.targetLang = {
          locale: modifiedResponse[2].value,
          name: modifiedResponse[2].display,
        };
        this.sourceLangList = modifiedResponse.map((lang: any) => ({
          locale: lang.value, // Locale (Language code)
          name: lang.display, // Language name
        }));
        this.targetLangList = response[0].language.map((lang: any) => ({
          locale: lang.value, // Locale (Language code)
          name: lang.display, // Language name
        }));
        this.getGlossaryList();
        //this.dataRetentionPeriod = response.retentionPeriod; // Update the retention period from API
      },
      error => {
        //this.loadLang = false;
        console.error('Error fetching saved languages:', error);
      }
    );
  }
  openGlossaryDialog() {
    this.showGlossory = true;
  }
  closeModal() {
    this.showGlossory = false;
  }
  onFileSelected(event: any) {
    this.files = Array.from(event.target.files) || [];
    if (!this.validateFiles()) {
      return;
    }

    if (this.files.length === 1) {
      this.fileNameTooltip = this.files[0].name; // Full file name
      this.truncatedFileName = this.truncateFileName(this.files[0].name, 20); // Truncate to 20 characters
    } else if (this.files.length > 1) {
      // Multiple files selected
      this.truncatedFileName = `${this.files.length} files chosen`;
      this.fileNameTooltip = this.files.map(file => file.name).join(', ');
    } else {
      this.fileNameTooltip = 'No file chosen';
      this.truncatedFileName = 'No file chosen';
    }
  }

  private truncateFileName(fileName: string, maxLength: number): string {
    if (fileName.length <= maxLength) {
      return fileName;
    }
    const extensionIndex = fileName.lastIndexOf('.');
    const extension =
      extensionIndex > -1 ? fileName.substring(extensionIndex) : '';
    const truncatedName = fileName.substring(
      0,
      maxLength - extension.length - 3
    ); // 3 for "..."
    return `${truncatedName}...${extension}`;
  }

  getKey(item: { [key: string]: string }): string {
    // Extract the first key from the object and assert its type
    return Object.keys(item)[0] as string; // Type assertion to string
  }

  getValue(item: { [key: string]: string }): string {
    // Extract the first value from the object and assert its type
    return Object.values(item)[0] as string; // Type assertion to string
  }
  onSourceLangChange(newSourceLang: { locale: string; name: string }) {
    this.sourceLang = newSourceLang;
    this.getGlossaryList(); // Call getGlossary after updating sourceLang
  }

  onTargetLangChange(newTargetLang: { locale: string; name: string }) {
    this.targetLang = newTargetLang;
    this.getGlossaryList(); // Call getGlossary after updating targetLang
  }
  getGlossaryList(): void {
    // this.loadLang = true;
    const headers = new HttpHeaders({
      // Add authorization headers or other headers if necessary
      // 'Authorization': 'Bearer your-auth-token',
    });
    const body = {
      source_language: this.sourceLang.locale,
      target_language: this.targetLang.locale,
    };
    // Make the GET request to fetch the glossary list
    this.httpClient
      .post(environment.get_user_glossary, body, { headers })
      .subscribe(
        response => {
          // this.loadLang = false;
          console.log('Glossary list fetched successfully', response);
          // Handle the response, maybe store it in a variable to display on the UI
          this.glossaryList = response;
          this.selectedGlossary = this.glossaryList[0];
        },
        error => {
          // this.loadLang = false;
          console.error('Failed to fetch glossary list', error);
          // Handle the error, display error messages if needed
        }
      );
  }

  onGlossarySelect(glossary: any): void {
    this.loadGlossaryData(glossary);
  }

  loadGlossaryData(glossary: any): void {
    // Load the data (documents or content) associated with the selected glossary
    // For now, let's simulate with static data, but in a real-world scenario,
    // this can be fetched from an API or a service.

    // Simulating data associated with the glossary
    this.selectedGlossary = glossary;

    // Optionally, if the glossary doesn't have any documents, clear the data
    // this.selectedGlossaryData = [];
  }

  selectGlosssary(_glossary: any) {
    this.userAppliedglossary = _glossary.file_name;
    this.showGlossory = false;
    this.userAppliedglossaryFilePath = _glossary.file_path;
  }
  onSubmit() {
    if (!this.validateFiles(true)) {
      return;
    }

    this.downloadLinks = [];
    const translateUrl = `${environment.documentTranslationUrl}`;
    const formData = new FormData();

    this.files.forEach(file => {
      formData.append('files', file, file.name);
    });

    formData.append(
      'translation_request',
      JSON.stringify({
        source_language: this.sourceLang.locale,
        target_language: this.targetLang.locale,
        is_embedded_image: this.translateEmbeddedImages ? 'true' : 'false',
        file_name: this.userAppliedglossary || '',
        file_path: this.userAppliedglossaryFilePath || '',
      })
    );

    this.uploadService.upload(translateUrl, formData).subscribe({
      next: (response: any) => {
        if (this.files.length === 1) {
          const file = this.files[0];
          const blob = new Blob([response], {
            type: 'application/octet-stream',
          });
          const downloadLink = window.URL.createObjectURL(blob);
          const fileName = this.getFileNameWithLanguageCode(
            file.name,
            this.targetLang.locale
          );
          this.downloadLinks.push({ link: downloadLink, name: fileName });
          this.notificationService.showSuccess('');
        } else {
          this.clearFiles(this.fileUpload?.nativeElement);
          this.fetchFileStatuses();
          this.notificationService.showSuccess(
            'Translation request has been accepted , Translated Files will be available in sometime'
          );
        }
      },
      error: (error: any) => {
        if (error.detail) {
          this.notificationService.showError(
            'Translation failed :' + error.detail
          );
        } else {
          this.notificationService.showError(
            'Translation failed :' + error.message
          );
        }
      },
    });
  }

  validateFiles(skipFileRequiredCheck: boolean = false): boolean {
    this.fileRequiredError = false;
    this.fileTypeError = false;
    this.fileSizeError = false;
    this.filesNumberError = false;
    this.filesNameError = false;

    if (this.files.length === 0) {
      this.fileRequiredError = true;
      this.errorMessage = 'Please upload a document(s).';
      return false;
    }

    if (this.files.length > 10) {
      this.filesNumberError = true;
      this.errorMessage =
        'File Limit Exceeded: You can only upload up to 10 files at a time. Please remove some files and try again.';
      return false;
    }

    let totalSize = 0;
    for (let file of this.files) {
      if (invalidPattern.test(file.name)) {
        this.filesNameError = true;
        this.errorMessage = `The filename "${file.name}"  can't contain any of the following characters: \\ / : * ? , < > | #`;
        return false;
      }

      if (
        !this.doumentExtensionSupport.includes(
          this.getFileExtension(file.name).toLowerCase()
        )
      ) {
        this.fileTypeError = true;
        this.errorMessage =
          'Invalid file type. The supported formats are .ppt .pptx .doc .docx .xls .xlsx .pdf';
        return false;
      }
      totalSize += file.size;
      if (
        file.size > MAX_SINGLE_DOCUMENT_FILE_SIZE_BYTES ||
        totalSize > MAX_TOTAL_DOCUMENT_FILE_SIZE_BYTES
      ) {
        this.fileSizeError = true;
        this.errorMessage =
          'File size exceeds the allowed limit. A single file can be up to 25 MB, and the total size of all files can be up to 100 MB.';
        return false;
      }
    }

    return true;
  }

  switchValues() {
    let temp = this.sourceLang;
    this.sourceLang = this.targetLang;
    this.targetLang = temp;
    this.getGlossaryList();
  }

  switchImageQuality() {
    this.translateEmbeddedImages = !this.translateEmbeddedImages;
  }

  clearFiles(fileUpload: HTMLInputElement) {
    this.files = [];
    this.fileNames = [];
    this.fileRequiredError = false;
    this.filesNumberError = false;
    this.fileTypeError = false;
    this.fileSizeError = false;
    fileUpload.value = ''; // Clear the file input
    this.fileNameTooltip = 'No file chosen';
    this.truncatedFileName = 'No file chosen';
    this.cdr.detectChanges(); // Trigger change detection to update the view
  }

  fetchFileStatuses() {
    this.uploadService.getStatus('documents').subscribe({
      next: (response: any) => {
        this.statusError = false;
        this.fileStatuses = response;
        this.cdr.detectChanges();
      },
      error: (error: any) => {
        this.statusError = true;
        if (error.detail) {
          //this.notificationService.showError('Failed to fetch file statuses: ' + error.detail);
          this.errorDetail = error.detail;
        }
      },
    });
  }

  downloadfile(blob_folder_path: string, blob_name: string): void {
    this.uploadService.downloadFile(blob_folder_path, blob_name).subscribe({
      next: (blob: Blob) => {
        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = blob_name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
        this.notificationService.showSuccess('Download successfull !');
      },
      error: err => {
        console.error('Download failed', err);
        this.notificationService.showError('Download Falied');
      },
    });
  }

  private getFileNameWithLanguageCode(
    fileName: string,
    langCode: string
  ): string {
    const fileExtension = this.getFileExtension(fileName);
    const baseName = fileName.substring(0, fileName.lastIndexOf('.'));
    return `${baseName}_${langCode}.${fileExtension}`;
  }

  private getFileExtension(fileName: string): string {
    return fileName.split('.').pop() || '';
  }
}
