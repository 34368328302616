<div class="translator-card" role="region" aria-labelledby="translator-header">


  <div class="text-grid-container">
    <form #textTranslateForm="ngForm" (ngSubmit)="onSubmit()" aria-labelledby="translator-form-header">


      <!-- Language Selector -->
      <app-language-selector
        [sourceLangList]="sourceLangList"
        [targetLangList]="targetLangList"
        [sourceLang]="sourceLang"
        [targetLang]="targetLang"
        [showLanguageSwitch]="showLanguageSwitch"
        (sourceLangChange)="sourceLang = $event"
        (targetLangChange)="targetLang = $event"
        (switchValues)="switchValues()"
        class=""
        aria-label="Language Selector">
      </app-language-selector>

      <!-- Input Section -->
      <div class="grid-row">
        <div>
          <div class="form-group">
            <br />
            <div class="text-area-wrap">
              <scale-textarea
                id="input"
                name="input"
                label="Enter translation text"
                class="outline-none"
                max-length="50000"
                counter
                rows="10"
                columns="10"
                resize="none"
                ngDefaultControl
                [(ngModel)]="inputValue"
                #input="ngModel"
                [(ngModel)]="searchTerm"
                (ngModelChange)="onInputChange()"
                aria-required="true"
                aria-invalid="{{ textareaDirty && input.invalid }}"
                required
                [invalid]="textareaDirty && input.invalid">
              </scale-textarea>

              <!-- Error Message -->
              <div *ngIf="textareaDirty && input.invalid" class="error-message" role="alert" aria-live="assertive">
                Source text is required.
              </div>

              <!-- Clear Button -->
              <scale-icon-action-close 
                *ngIf="inputValue" 
                class="clear-text" 
                (click)="onReset()"
                selected
                accessibility-title="Clear source text" 
                aria-label="Clear source text">
              </scale-icon-action-close>
            </div>
          </div>
        </div>

        <!-- Output Section -->
        <div></div>
        <div>
          <div class="form-group">
            <br />
            <div class="text-area-overlay">
              <div class="copy-text">
                <scale-textarea
                  id="output"
                  name="output"
                  label="Translation output text"
                  class="form-control"
                  rows="10"
                  columns="10"
                  resize="none"
                  [value]="translatedText"
                  ngDefaultControl
                  [(ngModel)]="outputValue"
                  readonly
                  #output="ngModel"
                  aria-label="Translation Output"
                  tabindex="-1">
                </scale-textarea>

                <!-- Copy to Clipboard -->
                <span (click)="copyToClipBoard()" class="overlay" role="button" tabindex="0" aria-label="Copy translation text to clipboard">
                  <scale-icon-action-copy-paste class="copy-icon" accessibility-title="Copy to clipboard"></scale-icon-action-copy-paste>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Translate Button -->
      <div class="grid-row-button">
        <div class="translate-button">
          <scale-button type="submit" [disabled]="!inputValue" aria-disabled="{{ !inputValue }}" aria-label="Translate">
            <app-loading-spinner></app-loading-spinner>Translate
          </scale-button>
        </div>
      </div>
    </form>
  </div>
</div>
