import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css'],
})
export class LanguageSelectorComponent {
  @Input() sourceLangList: any[] = [];
  @Input() targetLangList: any[] = [];
  @Input() sourceLang: any;
  @Input() targetLang: any;
  @Input() admin!: boolean;
  @Input() showLanguageSwitch: boolean = false;
  @Output() sourceLangChange = new EventEmitter<any>();
  @Output() targetLangChange = new EventEmitter<any>();
  @Output() switchValues = new EventEmitter<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  onSourceChange(event: any) {
    // Emit the selected language when it changes
    if (event.name == 'Auto-Detect') {
      this.showLanguageSwitch = false;
    } else {
      this.showLanguageSwitch = true;
    }
    this.cdr.detectChanges();
    this.sourceLangChange.emit(event);
  }
  onTargetChange(event: any) {
    // Emit the selected language when it changes
    this.targetLangChange.emit(event);
  }
}
