import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  private reportsApiUrl = environment.reportsUrl;
  successfulFetch: boolean = false;

  startDate: any;
  endDate: any;

  private startDateInput!: string;
  private endDateInput!: string;

  signupForm!: FormGroup;

  totalHits!: number;
  groupUsage: any[] = [];

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    const currentDate = new Date();

    // get first date of the month in UTC timezone and ISO format
    this.startDate = new Date(
      Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 1)
    ).toISOString();
    // get current date in UTC timezone and ISO format
    this.endDate = new Date().toISOString();
    // the form needs dates after manipulation to show date correctly in the date picker interface
    this.signupForm = this.formBuilder.group({
      startDate: [this.startDate.split('T')[0], Validators.required],
      endDate: [this.endDate.split('T')[0], Validators.required],
    });
  }

  onSubmit() {
    this.signupForm.patchValue({
      startDate:
        this.startDateInput?.split('T')[0] || this.startDate.split('T')[0],
    });
    this.signupForm.patchValue({
      endDate: this.endDateInput?.split('T')[0] || this.endDate.split('T')[0],
    });
    let rawData;

    console.log(
      'url is>>>',
      `${this.reportsApiUrl}?start_date=${this.startDateInput || this.startDate}&end_date=${this.endDateInput || this.endDate}`
    );
    this.http
      .get<any>(
        `${this.reportsApiUrl}?start_date=${this.startDateInput || this.startDate}&end_date=${this.endDateInput || this.endDate}`
      )
      .subscribe({
        next: (data: any) => {
          rawData = data.report;
          this.groupUsage = data.report['group-usage'];
          this.totalHits = data.report['total-usage'];
          this.successfulFetch = true;
        },
        error: (err: any) => {
          this.successfulFetch = false;
          this.notificationService.showError(
            'Report fetching failed:' + err?.error?.detail
          );
        },
      });
  }

  handleKeyUp(event: any, element: string) {
    console.log('in handle key Up', event, element);
  }

  sortTable(element: string) {
    console.log('sort table function', element);
  }

  onDateChange(element: string, event: Event) {
    const customEvent = event as CustomEvent;
    const customEventData = customEvent.detail;
    const dateObj = new Date(customEventData.value);
    element === 'startDate'
      ? (this.startDateInput = dateObj.toISOString())
      : (this.endDateInput = dateObj.toISOString());
    //this.dateChange.emit(input.value); // Emit the date value
  }
}

/* const signupForm = new UntypedFormGroup({
  username: new UntypedFormControl('admin'),
  password: new UntypedFormControl({ value: '', disabled: false }),
  consent: new UntypedFormControl(),
  select: new UntypedFormControl('foo'),
  date: new UntypedFormControl(),
});
console.log('new form', signupForm.value);
console.log(this.fetchReportForm.value);
console.log(
  'url is>>>',
  `${this.reportsApiUrl}?start_date=2024-10-10T08:18:01Z&end_date=2024-10-24T08:18:01Z`
);
console.log('start', this.startDate); */
