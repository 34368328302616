<div
  class="grid-container-language"
  role="group"
  aria-labelledby="language-selection-header">
  <!-- Header for accessibility -->
  <!-- <h2 id="language-selection-header" class="sr-only">Language Selection</h2> -->

  <!-- First row: Labels -->
  <label for="sourceLanguageSelect" class="grid-item-language">
    <b>Source Language</b>
  </label>
  <div></div>
  <label for="targetLanguageSelect" class="grid-item-language">
    <b>Target Language</b>
  </label>

  <!-- Second row: Dropdowns and switch -->
  <ng-select
    id="sourceLanguageSelect"
    class="grid-item-language"
    [items]="sourceLangList"
    [ngModel]="sourceLang"
    bindLabel="name"
    (ngModelChange)="onSourceChange($event)"
    [clearable]="false"
    [searchable]="true"
    notFoundText="No matching languages found"
    aria-label="Source Language"
    aria-required="true">
  </ng-select>
  <span *ngIf="!showLanguageSwitch"> </span>
  <span
    *ngIf="showLanguageSwitch"
    class="grid-item-language switch-container"
    role="button"
    tabindex="0"
    (click)="switchValues.emit()"
    aria-label="Switch Source and Target Languages"
    title="Switch Source and Target Languages">
    <scale-icon-action-sort
      *ngIf="showLanguageSwitch"
      accessibility-title="Switch Languages"
      class="language-switch">
    </scale-icon-action-sort>
  </span>
  <ng-select
    id="targetLanguageSelect"
    class="grid-item-language"
    [items]="targetLangList"
    [ngModel]="targetLang"
    bindLabel="name"
    (ngModelChange)="onTargetChange($event)"
    [clearable]="false"
    [searchable]="true"
    notFoundText="No matching languages found"
    aria-label="Target Language"
    aria-required="true">
  </ng-select>
</div>
