import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';
import { NotificationService } from '../shared/services/notification.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface Language {
  locale: string;
  name: string;
}

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.css']
})
export class LanguagesComponent implements OnInit {
  languages: any[] = []; // Ensure this is an array of TagModel
  selectedItems: any[] = []; // Array to hold selected language codes
  dataRetentionPeriod: number = 1;
  loadLang = false;

  constructor(
    private languageService: LanguageService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadLanguages();
    this.loadSavedLanguages();
  }

  async loadLanguages() {
    try {
      const azureResponse = await this.languageService.getSupportedLanguagesFromAzure();

      // Map the azureResponse to display and value properties
      this.languages = Object.keys(azureResponse).map((langCode) => ({
        display: azureResponse[langCode].name,  // Get the 'name' property
        value: langCode,                        // Use the language code as 'value'
      }));
    } catch (error) {
      console.error('Error loading languages:', error);
    }
  }

  customMatchingFunction = (searchTerm: string, item: any): boolean => {
    if (!item || !item.name) return false;
    return item.name.toLowerCase().includes(searchTerm.toLowerCase());
  };

  onItemAdded(item: any) {
    this.selectedItems.push(item);
  }

  onItemRemoved(event: any) {
    this.selectedItems = this.selectedItems.filter(item => item.value !== event.value);
  }

  loadSavedLanguages() {
    this.loadLang = true;
    this.languageService.getSavedLanguagesFromTranslationApi().subscribe(response => {
      this.loadLang = false;
      // Assuming the response contains an array of saved languages
      this.selectedItems = response[0].language.map((lang: any) => ({
        value: lang.value,   // Locale (Language code)
        display: lang.display // Language name
      }));
      this.dataRetentionPeriod = response.retentionPeriod; // Update the retention period from API
    }, error => {
      this.loadLang = false;
      console.error('Error fetching saved languages:', error);
    });
  }

  saveSelectedLanguages() {
    const payload: any = {
      "languages": this.selectedItems.map(item => ({
        "value": item.value,   // Locale (Language code)
        "display": item.display // Language name
      }))
    };
    this.languageService.saveSelectedLanguages(payload).subscribe(response => {
      this.notificationService.showSuccess('Language saved successfully');
    }, error => {
      this.notificationService.showError('Failed to save Languages. Please try again');
    });
  }
}
