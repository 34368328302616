import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {
  languages: any[] = [];
  selectedItems: any[] = [];
  dataRetentionPeriod: number = 0;
  loadLang = false;

  constructor(private languageService: LanguageService, private configService: ConfigurationService, private notificationService :NotificationService) {} // Inject ConfigurationService

  ngOnInit(): void {
    this.loadDataRetentionPeriod(); // Load the initial retention period on component init
  }

  loadDataRetentionPeriod(): void {
    this.loadLang = true;
    this.configService.getRetentionPeriod().subscribe(
      (period: any) => {
        this.loadLang = false;
        this.dataRetentionPeriod = period[0].data_retention_period; // Set the loaded period
      },
      error => {
        this.loadLang = false;
        this.notificationService.showError('Error loading retention period');
      }
    );
  }

  saveSelectedLanguages(): void {
    this.loadLang = true;
    
    this.configService.saveRetentionPeriod(this.dataRetentionPeriod).subscribe(
      () => {
        this.loadLang = false;
        this.notificationService.showSuccess('Retention period saved successfully');
      },
      error => {
        this.loadLang = false;
        this.notificationService.showError('Error saving retention period');
      }
    );
  }
}
