import { Injectable } from '@angular/core';
import axios from 'axios';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private apiUrl = 'https://api.cognitive.microsofttranslator.com/languages?api-version=3.0';
  
  constructor(private http: HttpClient) {} // Inject HttpClient

  async getSupportedLanguagesFromAzure(): Promise<any> {
    try {
      const response = await axios.get(this.apiUrl);
      return response.data.translation;
    } catch (error) {
      console.error('Error fetching languages:', error);
      throw error;
    }
  }

  getSavedLanguagesFromTranslationApi(): Observable<any> {
    const apiUrl = environment.Getlanguage; // Placeholder API URL for fetching saved languages
    return this.http.get(apiUrl); // GET request to fetch saved languages
  }

  saveSelectedLanguages(payload: any): Observable<any> {
    const apiUrl = environment.Savelanguage; // Placeholder API URL for saving languages
    return this.http.post(apiUrl, payload, {
      headers: {
        'Content-Type': 'application/json', // Specify the content type
      },
      withCredentials: false // Use this if your API requires credentials
    });
  }
}
