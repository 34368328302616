<div class="configuration-container">
  <div class="configuration-row" *ngIf="!loadLang">
    <div class="configuration-column"></div>
    <div class="configuration-column">
      <h3>Data Retention period (in minutes)</h3>
      <input type="number" id="quantity" name="quantity" min="1" max="15" [(ngModel)]="dataRetentionPeriod" class="effect-1"><br><br>
    </div>
    <div class="configuration-column"></div>
    
    
  </div>

  <div class="configuration-row" *ngIf="!loadLang">
    <div class="configuration-column"></div>
    <scale-button style="margin-left: 90px;" type="submit" (click)="saveSelectedLanguages()">Save</scale-button>
    <div class="configuration-column"></div>
  </div>
  <app-loading-spinner *ngIf="loadLang"></app-loading-spinner>
</div>
