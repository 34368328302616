import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const permittedRoles = route.data['roles'] as Array<string>;
    const hasRole = permittedRoles.some(role => this.authService.hasRole(role));
    if (!hasRole) {
      alert('You do not have permission to access this page.');
      this.router.navigate(['/translate']); // Redirect to home or unauthorized page
      return false;
    } else {
      return true;
    }

    // Wait for the token to be acquired and roles to be set
    /*  if(this.authService.userRole){}
    const token = await this.authService.acquireToken();

    // Check if the user has the required 
    if(token){
      const hasRole = expectedRoles.some(role => this.authService.hasRole(role));
      if (!hasRole) {
        alert('You do not have permission to access this page.');
        this.router.navigate(['/']); // Redirect to home or unauthorized page
        return false;
      }
    }
    
    return true; */
  }
}
