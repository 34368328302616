<scale-telekom-header>
  <scale-telekom-nav-list slot="main-nav" aria-label="Main Navigation Links">
    <scale-telekom-nav-item aria-label="Translation Suite">
      <button type="button" aria-label="Translation Suite page" class="translation-suite-btn">
        Translation Suite
      </button>
    </scale-telekom-nav-item>
  </scale-telekom-nav-list>

  <scale-telekom-nav-list variant="functions" slot="functions" alignment="right" aria-label="Functions Menu" role="none">
    <scale-telekom-nav-item class="user-actions">
      <div class="icon-container">
        <scale-icon-service-settings
          accessibility-title="Setting"
          size="33"
          (click)="navigateToSettings()"
          aria-label="Setting"
        ></scale-icon-service-settings>
      </div>
      <div class="label-container">
        <label for="Setting" (click)="navigateToSettings()" aria-hidden="true">Setting</label>
      </div>
    </scale-telekom-nav-item>
    <scale-telekom-nav-item class="user-actions">
      <div class="icon-container">
        <scale-icon-action-logout
          accessibility-title="Logout"
          size="33"
          (click)="logout()"
          aria-label="Logout"
        ></scale-icon-action-logout>
      </div>
      <div class="label-container">
        <label for="logout" (click)="logout()" aria-hidden="true">Logout</label>
      </div>
    </scale-telekom-nav-item>
  </scale-telekom-nav-list>
</scale-telekom-header>
