<div class="translator-card">
  <div class="img-grid-container">
    <form #imgTranslateForm="ngForm" (ngSubmit)="onSubmit()">
      <app-language-selector
        [sourceLangList]="sourceLangList"
        [targetLangList]="targetLangList"
        [sourceLang]="sourceLang"
        [targetLang]="targetLang"
        (sourceLangChange)="sourceLang = $event"
        (targetLangChange)="targetLang = $event"
        (switchValues)="switchValues()">
      </app-language-selector>

      <div class="grid-row">
        <div class="grid-column">
          <p>
            Upload the image(s) to be translated.
            <br />
            <i class="h12"
              >The supported formats are .png .jpg .jpeg .tiff .bmp</i
            >
          </p>
          <input
            type="file"
            id="image-btn"
            hidden
            (change)="onImageSelected($event)"
            #fileUpload
            multiple
            aria-labelledby="image-btn-label"
            aria-describedby="file-types"
          />
        
          <!-- Custom upload button -->
          <label for="image-btn" class="custom-upload-btn" id="image-btn-label" role="button"
          tabindex="0"  (keydown)="handleFileUploadKeydown($event)"><b>Choose File</b></label>
          <!-- <p id="file-types" class="sr-only">The supported formats are .png, .jpg, .jpeg, .tiff, .bmp.</p> -->
        
          <!-- Display chosen file names -->
          <span id="file-chosen" [title]="fileNameTooltip" aria-live="polite">{{ truncatedFileName }}</span>
          <scale-icon-action-remove
            accessibility-title="Remove selected file"
            selected class="remove" size="17" 
            *ngIf="fileUpload.value" (click)="clearFiles(fileUpload)" role="button"  tabindex="0" (keydown.enter)="clearFiles(fileUpload)" (keydown.space)="clearFiles(fileUpload)"/>
          
          <div *ngIf="fileRequiredError" class="error-message" role="alert" aria-live="assertive">{{errorMessage}}</div>
          <div *ngIf="fileTypeError" class="error-message" role="alert" aria-live="assertive">{{errorMessage}}</div>
          <div *ngIf="fileSizeError" class="error-message" role="alert" aria-live="assertive">{{errorMessage}}</div>
          <div *ngIf="filesNumberError" class="error-message" role="alert" aria-live="assertive">{{errorMessage}}</div>
          <div *ngIf="filesNameError" class="error-message" role="alert" aria-live="assertive">{{errorMessage}}</div>
        </div>

        <div class="grid-column"></div>

        <div class="grid-column">
          <div class="container">
            <table>
              <tr>
                <p class="element">Retain image look & feel?</p>
                <label class="element-label">No</label>
                <scale-switch size="small" style="float: left; margin-top: 15px; font-weight: 500;" 
                  accessibility-title="Retain image look & feel?" 
                  title="Retain image look & feel?" label="Yes" 
                  (change)="switchImageQuality()" [checked]="preserveMetadataOn"></scale-switch>
              </tr>
              <tr class="element">
                <scale-button 
                  style="margin-left: 10px" 
                  type="submit" 
                  [disabled]="!files.length">
                  <app-loading-spinner></app-loading-spinner>
                  Translate
                </scale-button>
                
                <a *ngIf="downloadLink" class="preview" 
                   (click)="openModal(downloadLink)" 
                   alt="Preview translated image" 
                   aria-label="Preview translated image" tabindex="0">Preview translated image</a>
                <a *ngIf="downloadLink" [href]="downloadLink" 
                   [download]="translatedFileName" 
                   alt="Download translated image" 
                   class="download" 
                   aria-label="Download translated image" tabindex="0">Download translated image</a>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="grid-row-table">
        <div>
          <scale-table *ngIf="fileStatuses.length > 0 || statusError" class="centered-table scrollable-table">
            <table>
              <thead>
                <tr>
                  <th>Image Batch ID</th>
                  <th>Status</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody *ngIf="!statusError">
                <tr *ngFor="let file of fileStatuses">
                  <td>{{ file.batch_id }}</td>
                  <td>
                    <scale-tag *ngIf="file.status === 'failed'" size="small" color="red">{{file.status}}</scale-tag>
                    <scale-tag *ngIf="file.status === 'success'" size="small" color="green">{{file.status}}</scale-tag>
                    <scale-tag *ngIf="file.status !== 'failed' && file.status !== 'success'" size="small">{{file.status}}</scale-tag>
                  </td>
                  <td>
                    <scale-icon-action-download-from-cloud 
                      accessibility-title="Download batch file" 
                      [ngClass]="{'magenta-icon': file.status === 'success'}"
                      [attr.disabled]="file.status !== 'success' ? true : null"
                      (click)="file.status === 'success' && downloadfile(file.zip_file_path, file.zip_file_name)">
                    </scale-icon-action-download-from-cloud>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="statusError">
                <tr>
                  <td colspan="4" class="error-msg">
                    <scale-icon-action-circle-close 
                      accessibility-title="Error" size="12"/>
                    Failed to retrieve the status of the batch image 
                    <span *ngIf="errorDetail">: {{ errorDetail }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </scale-table>
        </div>
      </div>
    </form>
  </div>
</div>

<scale-modal heading="Image Preview" id="modal" [opened]="showModal" slot="action" (click)="closeModal()">
  <img *ngIf="previewImageUrl" [src]="previewImageUrl" alt="Preview Translated image" />
  <p *ngIf="!previewImageUrl">No image preview available.</p>
  <scale-button slot="action" variant="secondary" (click)="closeModal()"
    >Close</scale-button
  >
</scale-modal>
