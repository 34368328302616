<div class="grid-container" role="main" aria-labelledby="main-header">

  <!-- Header -->
  <div class="header" id="main-header">
    <app-header></app-header>
  </div>

  <!-- Back Link -->
  <div class="back-link">
    <scale-icon-home-home
      accessibility-title="Home"
      aria-label="Back to Translate"
      routerLink="/translate"
      fill="#e20074"
      style="cursor: pointer">
    </scale-icon-home-home>
  </div>

  <!-- Content -->
  <div class="content setting-content">
    <!-- Sidebar Navigation -->
    <nav aria-label="Settings Navigation">
      <scale-sidebar-nav>
        <scale-sidebar-nav-item
          *ngIf="showEnableLanguageSetting"
          [current]="activeComponent === 'languages'"
          [active]="activeComponent === 'languages'">
          <a
            href="javascript:void(0)"
            (click)="setActiveComponent('languages')"
            tabindex="0"
            
            >Languages</a>
        </scale-sidebar-nav-item>

        <scale-sidebar-nav-item
          *ngIf="showGlossaryMenu"
          [current]="activeComponent === 'glossary'"
          [active]="activeComponent === 'glossary'">
          <a
            href="javascript:void(0)"
            (click)="setActiveComponent('glossary')"
            tabindex="0"
            >Glossary</a>
        </scale-sidebar-nav-item>

        <scale-sidebar-nav-item
          *ngIf="showDashboard"
          [current]="activeComponent === 'dashboard'"
          [active]="activeComponent === 'dashboard'">
          <a
            href="javascript:void(0)"
            (click)="setActiveComponent('dashboard')"
            tabindex="0"
            >Dashboard</a>
        </scale-sidebar-nav-item>

        <scale-sidebar-nav-item
          *ngIf="showAppConfiguration"
          [current]="activeComponent === 'configuration'"
          [active]="activeComponent === 'configuration'">
          <a
            href="javascript:void(0)"
            (click)="setActiveComponent('configuration')"
            tabindex="0"
            >Configuration</a>
        </scale-sidebar-nav-item>
      </scale-sidebar-nav>
    </nav>

    <!-- Dynamic Content -->
    <div id="content" class="setting-component" tabindex="-1">
      <div *ngIf="activeComponent === 'languages'">
        <app-languages></app-languages>
      </div>

      <div *ngIf="activeComponent === 'glossary'">
        <app-glossary></app-glossary>
      </div>

      <div *ngIf="activeComponent === 'dashboard'">
        <app-dashboard></app-dashboard>
      </div>

      <div *ngIf="activeComponent === 'configuration'">
        <app-configuration></app-configuration>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <div class="footer">
    <app-footer></app-footer>
  </div>
</div>
