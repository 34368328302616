import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  //private baseUrl = 'https://dev.translate.dtse.telekom.net/utility/configuration/retention_period';

  constructor(private http: HttpClient) {}

  // Method to get the retention period
  getRetentionPeriod(): Observable<number> {
    return this.http.get<number>(environment.get_retention);
  }

  // Method to save the retention period
  saveRetentionPeriod(period: number): Observable<void> {
    const body = { time: period.toString() }
    return this.http.post<void>(environment.save_retention, body);
  }
}
