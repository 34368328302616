import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css']
})
export class LanguageSelectorComponent {
  @Input()
  cities: any[] = [];
  @Input() sourceLang: any;
  @Input() targetLang: any;
  @Input() admin!: boolean;
  @Output() sourceLangChange = new EventEmitter<any>();
  @Output() targetLangChange = new EventEmitter<any>();
  @Output() switchValues = new EventEmitter<void>();

  constructor() {}

  onSourceChange(event: any) {
    // Emit the selected language when it changes
    this.sourceLangChange.emit(event);
  }
  onTargetChange(event: any) {
    // Emit the selected language when it changes
    this.targetLangChange.emit(event);
  }
}
