<form [formGroup]="signupForm" (ngSubmit)="onSubmit()" aria-labelledby="group-report-header">
  <div class="grid-container">
    <h1 id="group-report-header" class="grid-header">Group-Based Usage Report</h1>

    <!-- Start Date -->
    <div class="grid-start-date">
      <scale-date-picker
        label="Start Date (in UTC)"
        id="startDate"
        name="startDate"
        required
        aria-required="true"
        aria-describedby="startDate-helper"
        formControlName="startDate"
        ngDefaultControl
        class="form-control"
        (scale-change)="onDateChange('startDate', $event)">
      </scale-date-picker>
      
    </div>

    <!-- End Date -->
    <div class="grid-end-date">
      <scale-date-picker
        label="End Date (in UTC)"
        id="endDate"
        name="endDate"
        value="2024-10-24"
        required
        aria-required="true"
        aria-describedby="endDate-helper"
        formControlName="endDate"
        ngDefaultControl
        class="form-control"
        (scale-change)="onDateChange('endDate', $event)">
      </scale-date-picker>
      
    </div>

    <!-- Submit Button -->
    <div class="grid-submit">
      <scale-button type="submit" aria-label="View report for selected dates">
        <app-loading-spinner aria-hidden="true"></app-loading-spinner>View Report
      </scale-button>
    </div>
  </div>
</form>
