<div class="glossary-container">
  <scale-tab-nav size="large">
    <!-- Tab: Create Glossary -->
    <scale-tab-header slot="tab" aria-label="Create a new glossary">
      <scale-icon-user-file-html-file 
        accessibility-title="Create glossary icon" 
        selected>
      </scale-icon-user-file-html-file>
      Create Glossary
    </scale-tab-header>
    <scale-tab-panel slot="panel">
      <div class="setting-row">
        <div class="setting-column" *ngIf="!loadLang">
          <app-language-selector
            [sourceLangList]="sourceLangList"
            [targetLangList]="targetLangList"
            [sourceLang]="sourceLang"
            [targetLang]="targetLang"
            (sourceLangChange)="sourceLang = $event"
            (targetLangChange)="targetLang = $event"
            [admin]="true"
            class=""
            aria-label="Language selection dropdowns">
          </app-language-selector>
        </div>
      </div>
      <div class="setting-row">
        <div class="setting-column" *ngIf="!loadLang">
          <h3 id="glossaryNameLabel">
            Glossary Name <span aria-hidden="true" class="required">*</span>
          </h3>
          
            
            <scale-textarea
            id="input"
            name="input"
            label="Enter glossary Name"
            max-length="50"
            counter
            required
            aria-required="true"
            rows="2"
            columns="2"
            resize="none"
            ngDefaultControl
            [(ngModel)]="glossaryName"
            (ngModelChange)="validateForm()">
          </scale-textarea>


          <div *ngIf="GlossaryNameRequiredError" class="error-message" aria-live="assertive">
            Glossary Name is required
          </div>
        </div>
        <div class="setting-column" style="padding-left: 40px" *ngIf="!loadLang">
          <h3 id="descriptionLabel">
            Description <span aria-hidden="true" class="required">*</span>
          </h3>


            <scale-textarea
                id="input"
                name="input"
                label="Enter glossary Description"
                max-length="20000"
                counter
                rows="2"
                required
                aria-required="true"
                columns="2"
                resize="none"
                ngDefaultControl
                [(ngModel)]="glossaryDescription"
                (ngModelChange)="validateForm()">
              </scale-textarea>
          <div *ngIf="GlossaryDescriptionRequiredError" class="error-message" aria-live="assertive">
            Description is required
          </div>
        </div>
      </div>
      <div class="setting-row">
        <div class="setting-column" *ngIf="!loadLang">
          <p>
            Upload the glossary document <br />
            <i class="h12">The supported formats are .csv</i><br />
            <a
              href="assets/glossary-reference-template.csv"
              download="glossary-reference-template.csv"
              aria-label="Download sample glossary template">
              Click here to download sample glossary template
            </a>
          </p>
          <input
            type="file"
            id="actual-btn"
            hidden
            (change)="validateFiles(fileUpload)"
            #fileUpload
            required
            aria-label="Upload a .csv glossary file"
            aria-required="true"
          />
        
          <!-- Label and Span in Row -->
          <div class="file-upload-container">
            <label for="actual-btn" class="custom-upload-btn" id="actual-btn-label"><b>Choose File</b></label>
            <span id="file-chosen" [title]="fileNameTooltip" aria-live="polite">{{ truncatedFileName }}</span>
          </div>
        
          <div *ngIf="fileRequiredError" class="error-message" aria-live="assertive">
            File is required
          </div>
          <div *ngIf="fileTypeError" class="error-message" aria-live="assertive">
            Only .csv files are allowed
          </div>
        </div>
        
        <div class="setting-column" *ngIf="!loadLang">
          <scale-button
            style="margin-left: 10px"
            type="submit"
            (click)="onSave()"
            aria-label="Save glossary details">
            Save
          </scale-button>
        </div>
      </div>
    </scale-tab-panel>

    <!-- Tab: View Glossary -->
    <scale-tab-header slot="tab" aria-label="View existing glossaries">
      <scale-icon-user-file-file-collection 
        accessibility-title="View glossary collection icon" 
        selected>
      </scale-icon-user-file-file-collection>
      View Glossary(s)
    </scale-tab-header>
    <scale-tab-panel slot="panel">
      <div
        class="setting-row setting-center"
        style="margin-top: 30px"
        *ngIf="glossaryList.length > 0">
        <scale-table>
          <table aria-label="Glossary list">
            <thead>
              <tr>
                <th id="title">File name</th>
                <th>Source language</th>
                <th>Target language</th>
                <th>Description</th>
                <th>Created by</th>
                <th>Created date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let glossary of glossaryList">
                <td>{{ glossary.file_name }}</td>
                <td>{{ glossary.source_lang | uppercase }}</td>
                <td>{{ glossary.target_lang | uppercase }}</td>
                <td>{{ glossary.description }}</td>
                <td>{{ glossary.created_by }}</td>
                <td>{{ glossary.created_on | date: 'yyyy-MM-dd' }}</td>
                <td>
                  <a class="glossary-list" (click)="downloadGlossary(glossary)" aria-label="Download">
                    Download
                  </a>
                  |
                  <a class="glossary-list" (click)="deleteGlossary(glossary)" aria-label="Delete">
                    Delete
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </scale-table>
      </div>
    </scale-tab-panel>
  </scale-tab-nav>
  <app-loading-spinner *ngIf="loadLang" aria-label="Loading, please wait"></app-loading-spinner>
</div>
